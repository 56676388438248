import React, { Component } from "react";
import { Row, Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import sweetAlert from "sweetalert";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import ScrollContainer from "react-indiana-drag-scroll";
import arrayMove from "array-move";
import axios from "axios";
import { store } from "react-notifications-component";
import { API_URL, getLoggedInUser } from "../../../helpers/authUtils";

const instance = axios.create();

const SortableItem = sortableElement(
  ({
    columns,
    singleData,
    indexId,
    editButtonLink,
    deleteRecord,
    gearButtonLink,
    user,
  }) => (
    
    <tr key={indexId}>
      <th className="align-middle text-center" scope="row">
        <span className="mr-1">
          <i className="mdi mdi-drag"></i>
        </span>
        {/* {indexId + 1} */}
      </th>
      {columns.map((singleColumnData, index) => {
        let keyArray = singleColumnData.key.split(".");
        let tempData = singleData[keyArray[0]];
        for (let i = 1; i < keyArray.length; i++) {
          tempData = tempData[keyArray[i]];
        }
        return (
          
          <td key={index} className="align-middle table-data">
            {singleColumnData.type === "Boolean" ? (
              tempData === true ? (
                <span className="badge font-size-11 badge-success">YES</span>
              ) : (
                <span className="font-size-11 badge badge-danger">NO</span>
              )
            ) : singleColumnData.complexityFormat ? (
              singleColumnData.complexityFormat(tempData)
            ) : (
              tempData
            )}
          </td>
        );
      })}
      <td className="action align-middle sticky-column">
        <Row noGutters className="action-wrapper justify-content-center">
          <Link
            to={{
              pathname: editButtonLink + "/" + singleData._id,
              data: {
                singleData,
              },
            }}
          >
            <Button
              color="primary"
              size="sm"
              className="waves-effect waves-light"
            >
              <span className="mr-1">
                <i className="fas fa-pencil-alt"></i>
              </span>
              Edit
            </Button>
          </Link>
          {user.mail === "admin@harnstech.com" && (
            <Button
              color="danger"
              size="sm"
              className="waves-effect waves-light ml-2"
              onClick={() => deleteRecord(singleData)}
            >
              <span className="mr-1">
                <i className="fas fa-trash-alt"></i>
              </span>
              Delete
            </Button>
          )}
          {gearButtonLink && (
            <Link
              to={{
                pathname: gearButtonLink + "/" + singleData._id,
                data: {
                  singleData,
                },
              }}
            >
              <Button
                color="secondary"
                size="sm"
                className="waves-effect waves-light ml-2"
              >
                <span className="mr-1">
                  <i className="fas fa-lock"></i>
                </span>
                Access
              </Button>
            </Link>
          )}
        </Row>
      </td>
    </tr>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

class MovableDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [],
      pageData: [],
    };
  }

  sortedDataHandel = () => {};
  async componentDidMount() {
    let user = await getLoggedInUser();
    this.setState({ user });
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ pageData }) => {
        return {
          pageData: arrayMove(pageData, oldIndex, newIndex).map(
            (data, index) => {
              return {
                ...data,
                priority: index + 1,
              };
            }
          ),
        };
      },
      () => {
        instance
          .post(API_URL + this.props.priorityPostLink, this.state.pageData)
          .then((res) => {
            store.addNotification({
              title: this.props.pageName,
              message: "Priority updated successfully.",
              type: "success",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
              },
            });
            return res;
          })
          .catch((err) => {
            console.log("error in post user gr", err);
          });
      }
    );
  };

  getSnapshotBeforeUpdate(prevProps) {
    if (
      prevProps.data &&
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)
    ) {
      this.setState({
        pageData: this.props.data,
      });
    }
    return null;
  }
  componentDidUpdate() {}

  deleteRecord = (recordId) => {
    
    sweetAlert({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this !",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: { text: "Yes", className: "sweet-alert-red-button" },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.handleDelete(recordId);
        sweetAlert("Deleted successfully!", {
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              className: "sweet-alert-ok-button",
            },
          },
        });
      }
    });
  };

  render() {
    return (
      <ScrollContainer
        hideScrollbars={false}
        className="table-responsive scroll-container"
      >
        <Table className="table">
          <thead>
            <tr>
              <th className="index-column">
                {/*<span className="mr-1">
                                    <i className="mdi mdi-drag"></i>
                                </span>*/}
                &nbsp;
              </th>
              {this.props.columns &&
                this.props.columns.map((singleColumns, index) => {
                  return <th key={index}>{singleColumns.label}</th>;
                })}
              <th className="sticky-column text-center justify-content-center">
                Action
              </th>
            </tr>
          </thead>
          <SortableContainer
            helperClass="sortableHelper"
            onSortEnd={this.onSortEnd}
          >
            {this.state.pageData &&
              this.state.pageData.map((singleData, index) => (
                <SortableItem
                  columns={this.props.columns}
                  key={index}
                  singleData={singleData}

                  index={index}
                  indexId={index}
                  editButtonLink={this.props.editButtonLink}
                  deleteRecord={() => this.deleteRecord(singleData)}
                  gearButtonLink={this.props.gearButtonLink}
                  user={this.state.user}
                />
              ))}
          </SortableContainer>
        </Table>
      </ScrollContainer>
    );
  }
}

export default MovableDataTable;
