import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import {
  CardBody,
  Card
} from "reactstrap";
import { Bar, Pie } from 'react-chartjs-2';
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import {
  setBreadcrumbItems,
  setBreadcrumbButtonAction,
} from "../../store/actions";

//Import Components
import {
  API_URL,
} from "../../helpers/authUtils";
import axios from "axios";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [],
      widgetCounts: {}
    };
  }

  async componentDidMount() {
    this.props.setBreadcrumbItems("Dashboard", this.state.breadcrumbItems);
    this.props.setBreadcrumbButtonAction([]);
    axios.get(`${API_URL}settings/counts`).then(response => {
      this.setState({widgetCounts: response.data})
    })
  }

  render() {

    const {widgetCounts} = this.state;

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Chart.js Bar Chart',
        },
      },
    };
    
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', "Aug", 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const data = {
      labels,
      datasets: [
        {
          label: 'Usage Count',
          data: [95, 23, 45, 98, 74, 12, 99, 87, 67, 54, 97, 79],
          backgroundColor: 'rgba(53, 162, 235, 0.9)',
        }
      ],
    };

    const data1 = {
      labels: ['UAE', 'USA', 'India', 'Turkey', 'Iran', 'Oman'],
      datasets: [
        {
          label: '# of Votes',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 0.9)',
            'rgba(54, 162, 235, 0.9)',
            'rgba(255, 206, 86, 0.9)',
            'rgba(75, 192, 192, 0.9)',
            'rgba(153, 102, 255, 0.9)',
            'rgba(255, 159, 64, 0.9)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    return (
      <React.Fragment>
        <Row>
          <Col className="ml-2 p-0">
            <Row>
              <Col md="6">
                <Card className="mini-stat bg-danger">
                  <CardBody className="mini-stat-img card-radii pb-1">
                    <div className="row">
                      <div className="col d-flex flex-column text-light">
                        <h5 className="m-0 font-weight-lighter">Total Users</h5>
                        <span className="h1 pt-2">786</span>
                      </div>
                      <div className="mini-stat-icon m-0">
                        <i className="mdi mdi-account-group-outline h2 float-right"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              

              <Col md="6">
                <Card className="mini-stat bg-primary">
                  <CardBody className="mini-stat-img card-radii pb-1">
                    <div className="row">
                      <div className="col d-flex flex-column text-light">
                        <h5 className="m-0 font-weight-lighter">Total Brands</h5>
                        <span className="h1 pt-2">65</span>
                      </div>
                      <div className="mini-stat-icon m-0">
                        <i className="mdi mdi-briefcase h2 float-right"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="6">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title mb-4">Usage / Conversion Statistics</h4>
                <Bar options={options} data={data} />
              </div>
            </div>    
          </Col>

          <Col md="12" lg="6">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title mb-4">Contry Wise Usage Statistics</h4>
                <Pie data={data1} />
              </div>
            </div>    
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect(null, { setBreadcrumbItems, setBreadcrumbButtonAction })(
  Dashboard
);
