import {
    SET_BREADCRUMB_ITEMS,
    SET_BUTTON_ACTION,
    SET_BREADCRUMB_BUTTON_ACTION,
} from './actionTypes';

const initialState = {
    title: 'Dashboard',
    breadcrumbItems: [
        { title: 'Lexa', link: '#' },
        { title: 'Dashboard', link: '#' },
        { title: 'Dashboard', link: '#' },
    ],
    breadcrumbButtons: [],
    buttonName: '',
    buttonLink: '',
    buttonFunc: '',
};

const layout = (state = initialState, action) => {
    switch (action.type) {
        case SET_BREADCRUMB_ITEMS:
            state = {
                ...state,
                title: action.payload.title,
                breadcrumbItems: action.payload.items,
            };
            break;
        case SET_BUTTON_ACTION:
            state = {
                ...state,
                buttonName: action.payload.buttonName,
                buttonLink: action.payload.buttonLink,
                buttonFunc: action.payload.buttonFunc,
            };
            break;
        case SET_BREADCRUMB_BUTTON_ACTION:
            state = {
                ...state,
                breadcrumbButtons: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default layout;
