import {
    SET_BREADCRUMB_ITEMS,
    SET_BUTTON_ACTION,
    SET_BREADCRUMB_BUTTON_ACTION,
} from './actionTypes';

export const setBreadcrumbItems = (title, items) => ({
    type: SET_BREADCRUMB_ITEMS,
    payload: {
        title: title,
        items: items,
    },
});

export const setButtonAction = (buttonName, buttonLink, buttonFunc) => {
    return {
        type: SET_BUTTON_ACTION,
        payload: {
            buttonName: buttonName,
            buttonLink: buttonLink,
            buttonFunc: buttonFunc,
        },
    };
};

export const setBreadcrumbButtonAction = (breadcrumbButtons) => {
    return {
        type: SET_BREADCRUMB_BUTTON_ACTION,
        payload: breadcrumbButtons,
    };
};
