import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { toggleSidebar, changeSidebarType } from "../../../store/actions";

//Import Menus
import FullScreen from "../Menus/full-screen";
import NotificationMenu from "../Menus/notification-menu";
import ProfileMenu from "../Menus/profile-menu";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };
  }

  /*componentDidMount(){
    this.sidebarToggle()
  }*/

  sidebarToggle = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile);
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile);
    }
  };

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      style={{ marginLeft: "-13px" }}
                      src={require("./../../../assets/images/logo.png")}
                      alt=""
                      height="30"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={require("./../../../assets/images/logo.png")}
                      alt=""
                      height="70"
                    />
                  </span>
                </Link>

                <Link to="/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      style={{ marginLeft: "-13px" }}
                      src={require("./../../../assets/images/logo.png")}
                      alt=""
                      height="30"
                      width="50"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={require("./../../../assets/images/logo.png")}
                      alt=""
                      height="50"
                    />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={this.sidebarToggle}
                className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="mdi mdi-menu"></i>
              </button>

              {/*!["Add Consumption"].includes(this.props.title) && (
                <h4 className="mb-0 text-capitalize align-self-center">
                  {this.props.title}
                </h4>
              )*/}
            </div>

            <div className="d-flex">

              <FullScreen />

              <NotificationMenu />

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const BreadcrumbData = state.Breadcrumb;
  const { is_toggle, leftSideBarType } = state.Layout;
  return { is_toggle, leftSideBarType, title: BreadcrumbData.title };
};

export default withRouter(
  connect(mapStatetoProps, { toggleSidebar, changeSidebarType })(Topbar)
);
