import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import comlogo from '../../../assets/images/com-logo.svg';

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <Container fluid>
                        <Row className="align-items-center">
                            <Col
                                lg="6"
                                md="6"
                                sm="3"
                                xs="4"
                                className="text-left"
                            >
                                <img src={comlogo} alt="" height="20" />
                            </Col>
                            <Col
                                lg="6"
                                md="6"
                                sm="9"
                                xs="8"
                                className="text-right all-right-reserve"
                            >
                                © 2021 - {new Date().getFullYear()} Witspeak{' '}
                                <span className="d-sm-inline-block">
                                    {' '}
                                    -{' '}
                                    <a href="https://www.witspeak.com">
                                        Witspeak Inc.
                                    </a>{' '}
                                    All rights reserved.
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;
