import React, { Component } from "react";
import { Row, Col, Table, Button } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import sweetAlert from "sweetalert";
import Switch from "react-switch";
import ScrollContainer from "react-indiana-drag-scroll";
import Moment from "moment";
import axios from "axios";
import {
  API_URL
} from "../../../helpers/authUtils";
// import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
const instance = axios.create();

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [],
      pageData: [],
      shouldRedirect: false,
      switch : []
    };
    this.changeswitch = this.changeswitch.bind(this);   
  }

  changeswitch(id) { 
    let arr = [...this.state.switch];
    arr[id] = !arr[id];
    this.setState({ switch: arr });  
    
    //update database row
    const url = `${API_URL}${this.props.pagename.toLowerCase()}/${id}`
    axios.put(url, {status: arr[id] ? 1 : 0});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });

      //setting switch state
      if(this.props.switch){
        let arr = [];
        this.props.data.forEach(item => {
          arr[item.id] = item.status === 1 ? true : false;
        })
        this.setState({switch: arr});
      }
    }

    if (prevProps.filterData !== this.props.filterData) {
      if (
        this.props.filterData &&
        Object.keys(this.props.filterData).length > 0
      ) {
        let keys = Object.keys(this.props.filterData);

        if (this.props.pagename === "Products") {
          instance
            .get(
              API_URL +
                "productlist/filter/" +
                (typeof this.props.filterData[keys[0]] !== "undefined" &&
                this.props.filterData[keys[0]] !== ""
                  ? this.props.filterData[keys[0]]
                  : null) +
                "/" +
                (typeof this.props.filterData[keys[1]] !== "undefined" &&
                this.props.filterData[keys[1]] !== ""
                  ? this.props.filterData[keys[1]]
                  : null)
            )
            .then((response) => {
              this.setState({
                data: response.data,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.setState({
            data: this.props.data.filter((singleItem) => {
              let rval = [];
              for (let i = 0; i < keys.length; i++) {
                if (keys[i] === "_id") {
                  rval.push(
                    singleItem[keys[i]] === this.props.filterData[keys[i]]
                  );
                } else {
                  rval.push(
                    singleItem.hasOwnProperty(keys[i]) &&
                      singleItem[keys[i]]
                        .toLowerCase()
                        .includes(this.props.filterData[keys[i]].toLowerCase())
                  );
                }
              }
              return eval(rval.join(" && "));
            }),
          });
        }
      } else {
        this.setState({
          data: this.props.data,
        });

        //setting switch state
        if(this.props.switch){
          let arr = [];
          this.props.data.forEach(item => {
            arr[item.id] = item.status === 1 ? true : false;
          })
          this.setState({switch: arr});
        }
      }
    }

    console.log(this.state.switch);
  }

  redirectTOForm = async (data) => {
    this.setState({
      press: data.press.id,
      _id: data._id,
      shouldRedirect: true,
    });
  };

  deleteRecord = (record) => {
    sweetAlert({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this !",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: { text: "Yes", className: "sweet-alert-red-button" },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.handleDelete(record);
        sweetAlert("Deleted successfully!", {
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              className: "sweet-alert-ok-button",
            },
          },
        });
      }
    });
  };

  resetData = (record) => {
    sweetAlert({
      title: "Are you sure?",
      text: "This will reset all the data for given LPS!",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: { text: "Yes", className: "sweet-alert-red-button" },
      },
      dangerMode: true,
    }).then((willReset) => {
      if (willReset) {
        this.props.handleReset(record);
      }
    });
  };

  render() {
    return (
      <ScrollContainer
        hideScrollbars={false}
        className="table-responsive scroll-container"
      >
        <Table className={"table " + this.props.customCssClass}>
          <thead>
            <tr>
              <th className="index-column">#</th>
              {this.props.columns &&
                this.props.columns.map((singleColumns, i) => {
                  return <th key={i}>{singleColumns.label}</th>;
                })}

              {this.props.continueButton && this.state.Add && (
                <th className="sticky-column text-center justify-content-center"></th>
              )}
              {this.props.editplantProduct && (
                <th className="sticky-column text-center justify-content-center"></th>
              )}
              {this.props.editOspProduct && (
                <th className="sticky-column text-center justify-content-center"></th>
              )}
              {this.props.switch && <th className="sticky-column text-center justify-content-center">
                  Status
              </th>}
              {(this.props.diffButtons || this.props.actions) && (
                <th className="sticky-column text-center justify-content-center">
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.state.data &&
              this.state.data.map((singleData, index) => (
                <tr key={index}>
                  <th
                    className="align-middle text-center"
                    scope="row"
                  >
                    {this.props.startIndex + index}
                  </th>
                  {this.props.columns.map((singleColumnData, index) => {
                    let keyArray = singleColumnData.key.split(".");
                    let tempData = singleData[keyArray[0]];
                    for (let i = 1; i < keyArray.length; i++) {
                      tempData = tempData[keyArray[i]];
                    }
                    return (
                      <td
                        key={index}
                        className="align-middle"
                      >
                        {singleColumnData.type === "Image" ? (
                          tempData != null ? <img
                            src={tempData}
                            className="avatar-sm rounded"
                            alt={singleColumnData.alt + "_image"}
                          /> : null
                        ) : singleColumnData.type === "Count" ? (
                          <span className="badge badge-soft-danger font-size-13 badge-pill">{tempData?.length}</span>
                        ) : singleColumnData.type === "Audio" ? (
                          typeof tempData != 'undefined' ? <audio controls><source src={tempData} type="audio/mpeg" /></audio> : null
                        ) : singleColumnData.type === "Email" ? (
                          <a href={"mailto:"+tempData}>{tempData}</a>
                        ) : singleColumnData.type === "Url" ? (
                          <a href={tempData} target="_blank">{tempData}</a>
                        ) : singleColumnData.type === "Tel" ? (
                          <a href={"tel:"+tempData} target="_blank">{tempData}</a>
                        ) : singleColumnData.type === "Number" ? (
                          <span className="badge badge-soft-danger font-size-13 badge-pill">{tempData}</span>
                        ) : singleColumnData.type === "Date" ||
                          singleColumnData.type === "Time" ? (
                          Moment(tempData).format(singleColumnData.format)
                        ) : singleColumnData.type === "ISTDate" ? (
                          Moment(tempData)
                            .utc(false)
                            .format(singleColumnData.format)
                        ) : singleColumnData.type === "Decimal" ? (
                          tempData?.toFixed(2)
                        ) : (singleColumnData.type === "StringBadge") ? (
                          singleData[singleColumnData.cond] ? <div className='text-capitalize'>{tempData} <sup class='badge bg-primary text-light'>Respoke</sup></div> : tempData
                        ) : singleColumnData.type === "Array" ? (
                          singleData[singleColumnData["key"]].map((item, i) => (
                            <p key={i} className="mb-0">
                              {item.first_name + " " + item.last_name}
                            </p>
                          ))
                        ) : singleColumnData.type === "KeylessArray" ? (
                          singleData[singleColumnData["key"]].map((item, i) => (
                            <p key={i} className="mb-0">
                              {item}
                            </p>
                          ))
                        ) : singleColumnData.type === "TrialArray" ? (
                          singleData[singleColumnData["key"]]?.map(
                            (item, i) => (
                              <p key={i} className="mb-0">
                                {item}
                              </p>
                            )
                          )
                        ) : (
                          tempData
                        )}

                        {singleColumnData["concat"] &&
                        typeof singleData[singleColumnData["concat"]] !==
                          "undefined"
                          ? singleColumnData["separator"]
                            ? singleColumnData["separator"] +
                              "" +
                              singleData[singleColumnData["concat"]]
                            : " " + singleData[singleColumnData["concat"]]
                          : ""}

                        {singleColumnData["suffix"]
                          ? tempData
                            ? " " + singleColumnData["suffix"]
                            : ""
                          : ""}
                      </td>
                    );
                  })}
                  {this.props.switch && 
                    <td className="text-center align-middle">
                      <Switch key={"switch"+singleData.id} checked={this.state.switch[singleData.id]} onChange={() => this.changeswitch(singleData.id)} />
                    </td>  
                  }
                  {this.props.actions && (
                    <td className="action align-middle">
                      <Row
                        noGutters
                        className="action-wrapper flex-nowrap justify-content-center"
                      > 
                          {this.props.editButtonLink && <Link
                            to={{
                              pathname:
                                this.props.editButtonLink +
                                "/" +
                                (typeof singleData.id !== "undefined"
                                  ? singleData.id
                                  : singleData.created_on),
                              data: {
                                singleData,
                              },
                            }}
                          >
                            <Button
                              color="primary"
                              size="sm"
                              className="waves-effect waves-light"
                            >
                              <span className="mr-1">
                                <i className="fas fa-pencil-alt"></i>
                              </span>
                              Edit
                            </Button>
                          </Link>}
                          {this.props.viewButtonLink && <Link
                            to={{
                              pathname:
                                this.props.viewButtonLink +
                                "/" +
                                singleData.id,
                            }}
                          >
                            <Button
                              color="secondary"
                              size="sm ml-2"
                              className="waves-effect waves-light"
                            >
                              <span className="mr-1">
                                <i className="fas fa-file"></i>
                              </span>
                              View
                            </Button>
                          </Link>}
                          {this.props.deleteUrl && <Button
                            color="danger"
                            size="sm"
                            className="waves-effect waves-light ml-2"
                            onClick={() => this.deleteRecord(singleData)}
                          >
                            <span className="mr-1">
                              <i className="fas fa-trash-alt"></i>
                            </span>
                            Delete
                          </Button>}

                        {this.props.resetUrl && (
                          <Button
                            color="secondary"
                            size="sm"
                            className="waves-effect waves-light ml-2"
                            onClick={() => this.resetData(singleData)}
                          >
                            <span className="mr-1">
                              <i className="fas fa-redo"></i>
                            </span>
                            Reset
                          </Button>
                        )}

                        {/* condition to check gear button on table or not  */}
                        {this.props.gearButtonLink ? (
                          <Link
                            to={{
                              pathname:
                                this.props.gearButtonLink +
                                "/" +
                                singleData._id,
                            }}
                          >
                            <Button
                              color="secondary"
                              size="sm"
                              className="waves-effect waves-light ml-2"
                            >
                              <span className="mr-1">
                                <i className="mdi mdi-settings-transfer-outline"></i>
                              </span>
                              Feeder's
                            </Button>
                          </Link>
                        ) : null}
                        
                      </Row>
                    </td>
                  )}

                  {this.props.continueButton && this.state.Add && (
                    <td>
                      <Button
                        className="waves-effect waves-light ml-2"
                        color="outline-primary"
                        onClick={() => {
                          this.redirectTOForm(singleData);
                        }}
                      >
                        Continue
                        <i className="fa fa-arrow-right ml-1"></i>
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            {this.state.shouldRedirect && (
              <Redirect
                to={{
                  pathname:
                    "production-entry/add-production-entry" +
                    "/" +
                    this.state._id +
                    "/" +
                    this.state.press,
                }}
              />
            )}
          </tbody>
        </Table>

        {!this.state.data && (
          <Row>
            <Col className="flex-center">
              <i className="fas fa-3x text-black-50 mt-3 mb-3 fa-circle-notch fa-spin"></i>
            </Col>
          </Row>
        )}
      </ScrollContainer>
    );
  }
}

export default DataTable;
