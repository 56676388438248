import React, { Component } from 'react';
import { Col, Row, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { getLoggedInUser, getPlantId } from '../../../helpers/authUtils';
import { connect } from 'react-redux';
import {
  AvForm,
  AvField,
  AvRadio,
  AvInput,
  AvRadioGroup,
  AvGroup,
  AvCheckbox,
  AvCheckboxGroup,
} from 'availity-reactstrap-validation';

//Import Multiple Select
import Select from 'react-select';
import axios from 'axios';
import { store } from 'react-notifications-component';

//Import Action to copy breadcrumb items from local state to redux state
import {
  setBreadcrumbItems,
  setBreadcrumbButtonAction,
} from '../../../store/actions';

import { API_URL } from '../../../helpers/authUtils';

import FormConfig from './FormConfig.json';

import 'react-notifications-component/dist/theme.css';
import 'animate.css';

const instance = axios.create();

class FormAdvanced extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{title: 'Home', link: '/dashbaord'}, {title: this.props.pageName, link: '/'+this.props.pageName.toLowerCase()}, {title: this.props.formType.substring(0,1).toUpperCase()+""+this.props.formType.substring(1) + ' ' + this.props.pageName}],
      allSelectValues: {},
      shouldRedirect: false,
      data: {},
      images: {},
      image: null,
      sweet_timer: false,
      time: '',
      isValidSelect: {},
      selectedMulti: null,
      currentLoggedInUser: {},
      mindate: '',
    };

    this.handleAddValidSubmit = this.handleAddValidSubmit.bind(this);
    this.handleEditValidSubmit = this.handleEditValidSubmit.bind(this);
  }
  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };

  generateRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  async componentDidMount() {
    const details = await getLoggedInUser();
    
    this.setState({ currentLoggedInUser: details });
    this.props.setBreadcrumbItems(
      this.props.formType + ' ' + this.props.pageName,
      this.state.breadcrumbItems
    );

    this.props.setBreadcrumbButtonAction([]);

    let tempisValidSelect = {};

    FormConfig[this.props.pageName].forEach((singleField) => {
      if (
        singleField.type === 'Select' ||
        singleField.type === 'Multiple Select'
      ) {
        tempisValidSelect[singleField.requestKey] = true;
      }
    });

    this.setState({
      isValidSelect: tempisValidSelect,
    });

    if (this.props.formType === 'edit') {
      await instance
        .get(API_URL + this.props.entryPath + this.props.match.params.id+"/?v="+new Date().getTime())
        .then((response) => {
          let formData = {};
          FormConfig[this.props.pageName].forEach((data) => {
            if (data.type === 'Time') {
              // formData[data.requestKey] = moment(
              //   response.data[data.requestKey]
              // ).format(data.format);
              formData[data.requestKey] = new Date(
                response.data[data.requestKey]
              );
            } else {
              formData[data.requestKey] = response.data[data.requestKey];
            }
          });
          this.setState((prevState) => ({
            data: { ...prevState.data, ...response.data },
          }));
        })
        .catch(e => console.log(e.message))
    } else {
      if(this.props.pageName == 'Brands'){
        this.setState({data: {...this.state.data, secret_key: this.generateRandomString(20)}});
      }
    }
  }

  setName = (val) => {
    let index = this.state.emailOptions.findIndex((item) => item.mail === val);
    if (index !== -1) {
      let userObj = this.state.emailOptions[index];
      const name = userObj.given_name.split(' ');
      this.setState({
        data: {
          ...this.state.data,
          first_name: name[0],
          last_name: name[1],
          ldap_id: userObj._id,
        },
      });
    }
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (
      this.props.formType === 'edit' &&
      JSON.stringify(prevState) !== JSON.stringify(this.state) &&
      Object.keys(prevState.data).length > 0
    ) {
      FormConfig[this.props.pageName].forEach((singleField) => {
        if (
          (singleField.type === 'Select' ||
            singleField.type === 'Multiple Select' ||
            singleField.type === 'SelectCondition') &&
          this.state[singleField.requestKey + 'Options'] &&
          typeof this.state[singleField.requestKey + 'Value'] == 'undefined'
        ) {
          this.setState((prevState) => ({
            allSelectValues: {
              ...prevState.allSelectValues,
              [singleField.requestKey]: prevState.data[singleField.requestKey],
            },
            [singleField.requestKey + 'Value']:
              singleField.type === 'Select'
                ? prevState?.[singleField.requestKey + 'Options']?.filter(
                    (data) =>
                      data[singleField.optionvalue] ===
                      prevState.data[singleField.requestKey]
                  )?.[0]
                : prevState?.[singleField.requestKey + 'Options']?.filter(
                    (data) =>
                      prevState.data[singleField.requestKey].indexOf(
                        data[singleField.optionvalue]
                      ) > -1
                  ),
          }));
        } else if (
          singleField.type === 'Time' &&
          this.state.data[singleField.requestKey] &&
          !this.state[singleField.requestKey]
        ) {
          this.setState((prevState) => ({
            [singleField.requestKey]: moment(
              prevState.data[singleField.requestKey]
            )
              .utc(true)
              .format('HH:mm'),
          }));
        }
      });
    }
    return null;
  }

  getData(path, stateKey, hierarchykey) {
    if (
      !hierarchykey ||
      (hierarchykey && this.state.allSelectValues[hierarchykey])
    ) {
      instance
        .get(
          API_URL +
            path +
            (hierarchykey ? this.state.allSelectValues[hierarchykey] : '')
        )
        .then((response) => {
          this.setState({ [stateKey]: response.data });
        })
        .catch((err) => {
          console.log(err);
        });
      }
  }

  //add date while adding or editing form
  onDateChange = (e) => {
    this.setState({ time: e.target.value });
  };
  //  for csv files in SAP Upload - MB51s
  handleCsvFiles = (e) => {
    var file = e.target.files[0];
    if (file.size / 1024 > 15000) {
      store.addNotification({
        title: 'File',
        message: 'File size exceeded',
        type: 'danger',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      });
      e.target.value = '';
      return;
    } else {
      var data = new FormData();
      data.append('csv', file);
      data.append('user', this.state.currentLoggedInUser.id);
      this.setState({ file: data });
    }
  };
  //check file size and add it to image(string in state)
  handleFile = (e) => {
    const fieldname = e.target.name;
    
    var file = e.target.files[0];
    if (file.size / 1024 / 1024 > 2) {
      store.addNotification({
        title: 'File',
        message: 'Image size exceeded',
        type: 'danger',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      });

      e.target.value = '';
      return;
    } else {
      this.setState({images: {...this.state.images, [fieldname]: file}});
      /* var reader = new FileReader();
      var that = this;
      reader.onload = function (e) {
        let blob = e.target.result;
        that.setState({ image: blob });
      };
      reader.readAsDataURL(file); */
    }
  };

  notify(message, type){
    store.addNotification({
      title: this.props.pageName,
      message,
      type,
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 3000,
      },
    })
  }

  handleAddValidSubmit(event, value) {
    let isValidData = this.invalidSubmit();
    if (!isValidData) {
      return;
    }

    if(this.props.pageName == 'Category' && !this.state.images.image){
      this.notify("Image is required!","danger");
      return false;
    }

    if(this.props.pageName == 'Brands'){
      if(!this.state.images.logo){
        this.notify("Logo is required!","danger");
        return false;
      } else if(!this.state.images.image){
        this.notify("Image is required!","danger");
        return false;
      }
    }

    let values = {
        ...value,
        ...this.state.allSelectValues,
    };
    if((this.props.pageName == 'Category' || this.props.pageName == 'Brands') && Object.keys(this.state.images).length > 0) {
      let formdata = new FormData();
      if(this.props.pageName == 'Brands'){
        formdata.append("logo",this.state.images?.logo,this.state.images?.logo.name);
        formdata.append("image",this.state.images?.image,this.state.images?.image.name);
      } else if(this.props.pageName == 'Category'){
        formdata.append("image",this.state.images?.image,this.state.images?.image.name);
      }
      let keys = Object.keys(values);
      let vals = Object.values(values);
      for(let i = 0; i < keys.length; i++){
        formdata.append(keys[i], vals[i]);
      }
      values = formdata;
    }
    //console.log(values);
    //return false;

    instance
    .post(API_URL + this.props.entryPath, values)
    .then((response) => {
      store.addNotification({
        title: this.props.pageName,
        message: this.props.successMessage,
        type: 'success',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      });
      this.setState({
        shouldRedirect: true,
      });
    })
    .catch((err) => {
      store.addNotification({
        message: err.response.data,
        type: 'danger',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      });
    });
  }

  handleEditValidSubmit(event, value) {
    let isValidData = this.invalidSubmit();
    if (!isValidData) {
      return;
    }
    let values = {
      ...value,
      ...this.state.allSelectValues,
    };

    //appending file to values if uploaded
    if((this.props.pageName == 'Category' || this.props.pageName == 'Brands') && Object.keys(this.state.images).length > 0) {
      
      let formdata = new FormData();
      if(this.props.pageName == 'Brands'){
        if(this.state.images.logo){
          formdata.append("logo",this.state.images.logo,this.state.images.logo.name);
        }
        if(this.state.images.image){
          formdata.append("image",this.state.images?.image,this.state.images.image.name);
        }
      } else if(this.props.pageName == 'Category'){
        if(this.state.images.image){
          formdata.append("image",this.state.images?.image,this.state.images.image.name);
        }
      }
      let keys = Object.keys(values);
      let vals = Object.values(values);
      for(let i = 0; i < keys.length; i++){
        formdata.append(keys[i], vals[i]);
      }
      values = formdata;
    }

    instance
      .put(
        API_URL + this.props.entryPath + this.props.match.params.id,
        values
      )
      .then((response) => {
        store.addNotification({
          title: this.props.pageName,
          message: this.props.successMessage,
          type: 'success',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
        });
        this.setState({
          shouldRedirect: true,
        });
      })
      .catch((err) => {
        store.addNotification({
          message: err.response.data,
          type: 'danger',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
        });
      });
  }

  invalidSubmit = () => {
    let tempisValidSelect = {};
    Object.keys(this.state.isValidSelect).forEach((value) => {
      tempisValidSelect[value] =
        typeof this.state.allSelectValues?.[value] !== 'undefined';
    });
    this.setState({
      isValidSelect: tempisValidSelect,
    });
    return Object.values(tempisValidSelect).indexOf(false) === -1;
  };

  generatePassword() {
    var length = 15,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_&^%$#@!(){}[]+",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  } 

  render() {
    const customSelect2Styles = (id) => ({
      control: (styles, state) => ({
        ...styles,
        width: '100%',
        minHeight: 30,
        fontSize: '13px',
        color: '#5b626b',
        borderRadius: 0,
        boxShadow: state.isFocused ? '#ced4da' : 0,
        borderColor: state.isFocused
          ? '#ced4da'
          : this.state.isValidSelect[id] === undefined ||
            this.state.isValidSelect[id]
          ? '#ced4da'
          : '#ec536c',
        '&:hover': {
          borderColor: state.isFocused
            ? '#ced4da'
            : this.state.isValidSelect[id] === undefined ||
              this.state.isValidSelect[id]
            ? '#ced4da'
            : '#ec536c',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0 6px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '0px',
        flexWrap: 'initial',
      }),
      singleValue: (style, state) => ({
        color: '#000000',
      }),
      option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        svg: {
          color: state.isFocused
            ? '#ced4da'
            : this.state.isValidSelect[id] === undefined ||
              this.state.isValidSelect[id]
            ? '#ced4da'
            : '#ec536c',
        },
      }),
      indicatorContainer: (provided) => ({
        ...provided,
        svg: {
          padding: '0 8px',
        },
      }),
    });

    const customSelect2StylesMulty = (id) => ({
      control: (styles, state) => ({
        ...styles,
        width: '100%',
        minHeight: 30,
        fontSize: '13px',
        color: '#5b626b',
        borderRadius: 0,
        boxShadow: state.isFocused ? '#ced4da' : 0,
        borderColor: state.isFocused
          ? '#ced4da'
          : this.state.isValidSelect[id] === undefined ||
            this.state.isValidSelect[id]
          ? '#ced4da'
          : '#ec536c',
        '&:hover': {
          borderColor: state.isFocused
            ? '#ced4da'
            : this.state.isValidSelect[id] === undefined ||
              this.state.isValidSelect[id]
            ? '#ced4da'
            : '#ec536c',
        },
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: '0 6px',
        lineHeight: '1px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '0px',
      }),
      singleValue: (style) => ({
        color: '#000000',
      }),
      multiValue: (provided, state) => ({
        ...provided,
        height: '20px',
        lineHeight: '1px',
        alignItems: 'center',
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        overflow: 'show',
      }),
      option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        svg: {
          color: state.isFocused
            ? '#ced4da'
            : this.state.isValidSelect[id] === undefined ||
              this.state.isValidSelect[id]
            ? '#ced4da'
            : '#ec536c',
        },
      }),
      indicatorContainer: (provided) => ({
        ...provided,
        svg: {
          padding: '0 8px',
        },
      }),
    });

    const isDataPresent =
      Object.keys(this.state.data).length > 0 ? true : false;

    return (
      <React.Fragment>
        <Row noGutters>
          <Col lg="12">
            <Card className="row no-gutters">
              <Col lg="6" className="d-flex m-auto">
                <CardBody>
                  {(this.props.formType !== 'edit' ||
                    (this.props.formType === 'edit' &&
                      this.state.data &&
                      Object.keys(this.state.data).length > 0)) && (
                    <AvForm
                      onInvalidSubmit={this.invalidSubmit}
                      onValidSubmit={
                        this.props.formType === 'add'
                          ? this.handleAddValidSubmit
                          : this.handleEditValidSubmit
                      }
                      model={this.state.data}
                    >
                      {FormConfig[this.props.pageName].map((singleField, i) => {
                        if (singleField[this.props.formType + 'form']) {
                          if (singleField.type === 'Textbox') {
                            return (
                              <AvField
                                key={i + singleField.requestKey}
                                value={
                                  isDataPresent
                                    ? this.state.data[singleField.requestKey]
                                    : ''
                                }
                                readOnly={singleField.readonly}
                                name={singleField.requestKey}
                                label={singleField.fieldlabel}
                                placeholder={singleField.placeholder ?? singleField.fieldlabel}
                                type="text"
                                errorMessage={singleField.errorMessage}
                                validate={singleField.validate}
                                grid={{ lg: 8, md: 8, sm: 8 }}
                              />
                            );
                          } else if(singleField.type === 'Textarea'){
                            return(
                              <AvField
                                key={i + singleField.requestKey}
                                value={
                                  isDataPresent
                                    ? this.state.data[singleField.requestKey]
                                    : ''
                                }
                                onBlur={(e, val) => {
                                  if(this.props.pageName.toLowerCase() == 'brands' && singleField.requestKey == "name"){
                                    this.setState({data: {
                                      ...this.state.data, 
                                      username: val.toLowerCase().replace(/\s+/g,'_'),
                                      password: this.generatePassword()
                                    }})
                                  }
                                }}
                                name={singleField.requestKey}
                                label={singleField.fieldlabel}
                                placeholder={singleField.placeholder ?? singleField.fieldlabel}
                                type="textarea"
                                errorMessage={singleField.errorMessage}
                                validate={singleField.validate}
                                grid={{ lg: 8, md: 8, sm: 8 }}
                              />
                            );
                          } else if (singleField.type === 'Number') {
                            return (
                              <AvField
                                key={singleField.requestKey}
                                value={
                                  isDataPresent
                                    ? this.state.data[singleField.requestKey]
                                    : ''
                                }
                                name={singleField.requestKey}
                                label={singleField.fieldlabel}
                                placeholder={singleField.fieldlabel}
                                type="number"
                                errorMessage={singleField.errorMessage}
                                validate={singleField.validate}
                                grid={{ lg: 8, md: 8, sm: 8 }}
                              />
                            );
                          } else if (singleField.type === 'Checkbox') {
                            return (
                              <AvGroup row>
                                <Label
                                  className={
                                    this.state.isValidSelect[
                                      singleField.requestKey
                                    ] === false
                                      ? 'text-danger col-xs-12 col-sm-4 col-md-4 col-lg-4 control-label mb-0'
                                      : 'col-xs-12 col-sm-4 col-md-4 col-lg-4 control-label mb-0'
                                  }
                                >
                                  {singleField.fieldlabel}
                                </Label>
                                <div className="col-xs-12 col-sm-6 col-md-8 col-lg-8">
                                  <AvInput
                                    type="checkbox"
                                    key={singleField.requestKey}
                                    value={
                                      isDataPresent
                                        ? this.state.data[
                                            singleField.requestKey
                                          ]
                                        : ''
                                    }
                                    name={singleField.requestKey}
                                    label={singleField.fieldlabel}
                                    errorMessage={singleField.errorMessage}
                                    validate={singleField.validate}
                                  />
                                </div>
                              </AvGroup>
                            );
                          } else if (singleField.type === 'Select') {
                            return (
                              <FormGroup
                                key={singleField.requestKey}
                                row
                                className="align-items-center"
                              >
                                <Label
                                  className={
                                    this.state.isValidSelect[
                                      singleField.requestKey
                                    ] === false
                                      ? 'text-danger col-xs-12 col-sm-4 col-md-4 col-lg-4 control-label mb-0'
                                      : 'col-xs-12 col-sm-4 col-md-4 col-lg-4 control-label mb-0'
                                  }
                                >
                                  {singleField.fieldlabel}
                                </Label>
                                <div className="col-xs-12 col-sm-6 col-md-8 col-lg-8 select2">
                                  <Select
                                    styles={customSelect2Styles(
                                      singleField.requestKey
                                    )}
                                    maxMenuHeight={200}
                                    getOptionLabel={(option) => {
                                      return option[singleField.optionlabel];
                                    }}
                                    getOptionValue={(option) => {
                                      return option[singleField.optionvalue];
                                    }}
                                    onChange={async (event) => {
                                      if (
                                        this.props.pageName ===
                                          'Excel Reports' &&
                                        event.id === 1
                                      ) {
                                        this.setState({
                                          mindate: moment()
                                            .subtract(1, 'months')
                                            .format('YYYY-MM-DD'),
                                        });
                                      } else {
                                        this.setState({ mindate: '' });
                                      }
                                      await this.setState((prevState) => {
                                        let dependantValue = {};
                                        let dependantOption = {};
                                        if (singleField.dependantKey) {
                                          dependantValue = {
                                            [singleField.dependantKey]: '',
                                          };
                                          dependantOption = {
                                            [singleField.dependantKey +
                                            'Options']: [],
                                            [singleField.dependantKey +
                                            'Value']: [],
                                          };
                                        }
                                        return {
                                          [singleField.requestKey +
                                          'Value']: event,
                                          allSelectValues: {
                                            ...prevState.allSelectValues,
                                            [singleField.requestKey]:
                                              event[singleField.optionvalue],
                                            ...dependantValue,
                                          },
                                          ...dependantOption,
                                          isValidSelect: {
                                            ...prevState.isValidSelect,
                                            [singleField.requestKey]: true,
                                          },
                                        };
                                      });
                                    }}
                                    value={
                                      this.state[
                                        singleField.requestKey + 'Value'
                                      ] ?? []
                                    }
                                    options={
                                      this.state[
                                        singleField.requestKey + 'Options'
                                      ]?.length > 0 ||
                                      this.props.plantIdInSelect === 'true'
                                        ? this.state[
                                            singleField.requestKey + 'Options'
                                          ]
                                        : this.getData(
                                            singleField.path,
                                            singleField.requestKey + 'Options',
                                            singleField.hierarchykey
                                          )
                                    }
                                    className="select2"
                                  />
                                  {this.state.isValidSelect[
                                    singleField.requestKey
                                  ] === false ? (
                                    <div className="invalid-feedback">
                                      {
                                        singleField.validate.required
                                          .errorMessage
                                      }
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </FormGroup>
                            );
                          } else if (singleField.type === 'Datalist') {
                            return (
                              <>
                                <AvField
                                  autoComplete="new-password"
                                  type="email"
                                  list={singleField.listfor}
                                  validate={singleField.validate}
                                  placeholder={singleField.fieldlabel}
                                  name={singleField.requestKey}
                                  label={singleField.fieldlabel}
                                  grid={{ lg: 8, md: 8, sm: 8 }}
                                  onChange={(e, val) => {
                                    if (this.props.pageName === 'Operators') {
                                      this.setName(val);
                                    }
                                  }}
                                />
                                <datalist id={singleField.listfor}>
                                  {this.state[
                                    singleField.requestKey + 'Options'
                                  ] &&
                                    this.state[
                                      singleField.requestKey + 'Options'
                                    ].map((item, index) => (
                                      <option key={index} value={item.mail} />
                                    ))}
                                </datalist>
                              </>
                            );
                          } else if (singleField.type === 'SelectCondition') {
                            if (this.state.currentLoggedInUser.is_admin) {
                              return (
                                <FormGroup
                                  key={singleField.requestKey}
                                  row
                                  className="align-items-center"
                                >
                                  <Label
                                    className={
                                      this.state.isValidSelect[
                                        singleField.requestKey
                                      ] === false
                                        ? 'text-danger col-xs-12 col-sm-4 col-md-4 col-lg-4 control-label mb-0'
                                        : 'col-xs-12 col-sm-4 col-md-4 col-lg-4 control-label mb-0'
                                    }
                                  >
                                    {singleField.fieldlabel}
                                  </Label>
                                  <div className="col-xs-12 col-sm-6 col-md-8 col-lg-8 select2">
                                    <Select
                                      styles={customSelect2Styles(
                                        singleField.requestKey
                                      )}
                                      maxMenuHeight={200}
                                      getOptionLabel={(option) => {
                                        return option[singleField.optionlabel];
                                      }}
                                      getOptionValue={(option) => {
                                        return option[singleField.optionvalue];
                                      }}
                                      onChange={async (event) => {
                                        await this.setState((prevState) => {
                                          if (singleField.dependantKey) {
                                            let Obj = FormConfig[
                                              this.props.pageName
                                            ].find(
                                              (item) =>
                                                item.requestKey ===
                                                singleField.dependantKey
                                            );
                                            if (
                                              this.props.pageName ===
                                              'Blanket Mileage'
                                            ) {
                                              delete this.state.allSelectValues[
                                                Obj.requestKey
                                              ];
                                              this.setState({
                                                [Obj.requestKey + 'Value']: {},
                                              });
                                            }
                                            this.getData(
                                              Obj.path + '' + event.id,
                                              Obj.requestKey + 'Options'
                                            );
                                          }
                                          return {
                                            [singleField.requestKey +
                                            'Value']: event,
                                            allSelectValues: {
                                              ...prevState.allSelectValues,
                                              [singleField.requestKey]:
                                                event[singleField.optionvalue],
                                            },
                                            isValidSelect: {
                                              ...prevState.isValidSelect,
                                              [singleField.requestKey]: true,
                                            },
                                          };
                                        });
                                      }}
                                      value={
                                        this.state[
                                          singleField.requestKey + 'Value'
                                        ] ?? []
                                      }
                                      options={
                                        this.state[
                                          singleField.requestKey + 'Options'
                                        ]?.length > 0
                                          ? this.state[
                                              singleField.requestKey + 'Options'
                                            ]
                                          : this.getData(
                                              singleField.path,
                                              singleField.requestKey +
                                                'Options',
                                              singleField.hierarchykey
                                            )
                                      }
                                      className="select2"
                                    />
                                    {this.state.isValidSelect[
                                      singleField.requestKey
                                    ] === false ? (
                                      <div className="invalid-feedback">
                                        {
                                          singleField.validate.required
                                            .errorMessage
                                        }
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </FormGroup>
                              );
                            } else {
                              return (
                                <AvInput
                                  key={singleField.requestKey}
                                  value={
                                    this.state?.currentLoggedInUser
                                      ?.plant_details?.plant_id
                                  }
                                  name={singleField.requestKey}
                                  type="hidden"
                                />
                              );
                            }
                          } else if (singleField.type === 'RadioGroup') {
                            return (
                              <Row
                                key={singleField.requestKey}
                                className="form-group radio-wrapper"
                              >
                                <label className="col-xs-12 col-sm-4 col-md-4 col-lg-4 radio-label">
                                  {singleField.fieldlabel}
                                </label>
                                <AvRadioGroup
                                  inline
                                  name={singleField.requestKey}
                                  required
                                  errorMessage={singleField.errorMessage}
                                >
                                  {singleField.radioOptions.map(
                                    (singleRadioOption) => (
                                      <AvRadio
                                        key={singleRadioOption.radioName}
                                        label={singleRadioOption.radioName}
                                        value={singleRadioOption.radioValue}
                                      />
                                    )
                                  )}
                                </AvRadioGroup>
                              </Row>
                            );
                          } else if (singleField.type === 'File') {
                            return (
                              <FormGroup key={singleField.requestKey} row>
                                <Label className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                  {singleField.fieldlabel}
                                </Label>
                                <br />
                                <input
                                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                                  type={singleField.type}
                                  name={singleField.requestKey}
                                  onChange={this.handleFile}
                                  accept={singleField.accept}
                                />
                                <br />
                                <br />
                              </FormGroup>
                            );
                          } else if (singleField.type === 'Date') {
                            return (
                              <FormGroup key={singleField.requestKey}>
                                <AvField
                                  name={singleField.requestKey}
                                  label={singleField.fieldlabel}
                                  type="Date"
                                  min={this.state.mindate}
                                  validate={singleField.validate}
                                  errorMessage={singleField.errorMessage}
                                  value={
                                    isDataPresent
                                      ? moment(
                                          this.state.data[
                                            singleField.requestKey
                                          ]
                                        ).format('YYYY-MM-DD')
                                      : ''
                                  }
                                  grid={{
                                    lg: 8,
                                    md: 8,
                                    sm: 8,
                                  }}
                                />
                              </FormGroup>
                            );
                          } else if (singleField.type === 'Time') {
                            return (
                              <FormGroup>
                                <AvField
                                  name={singleField.requestKey}
                                  label={singleField.fieldlabel}
                                  onChange={(e) => {
                                    this.setState({
                                      [singleField.requestKey]: e.target.value,
                                    });
                                  }}
                                  value={
                                    isDataPresent
                                      ? this.state[singleField.requestKey]
                                      : ''
                                  }
                                  type="Time"
                                  errorMessage={singleField.errorMessage}
                                  grid={{
                                    lg: 8,
                                    md: 8,
                                    sm: 8,
                                  }}
                                />
                              </FormGroup>
                            );
                          } else if (singleField.type === 'Hidden') {
                            return (
                              <AvInput
                                key={singleField.requestKey}
                                value={
                                  singleField.value ??
                                  this.state.data[singleField.hiddenValue]
                                }
                                name={singleField.requestKey}
                                type="hidden"
                              />
                            );
                          } else if (singleField.pageName === 'Holiday') {
                            return (
                              <AvInput
                                key={singleField.requestKey}
                                value={this.state.currentLoggedInUser.id}
                                name={singleField.requestKey}
                                type="hidden"
                              />
                            );
                          } else if (singleField.type === 'PhoneNo') {
                            return (
                              <AvField
                                key={singleField.requestKey}
                                value={
                                  isDataPresent
                                    ? this.state.data[singleField.requestKey]
                                    : ''
                                }
                                max="10000000000"
                                min="1000000000"
                                name={singleField.requestKey}
                                label={singleField.fieldlabel}
                                placeholder={singleField.fieldlabel}
                                type="number"
                                errorMessage={singleField.errorMessage}
                                validate={singleField.validate}
                                grid={{ lg: 8, md: 8, sm: 8 }}
                              />
                            );
                          } else if (singleField.type === 'IPAddress') {
                            return (
                              <AvField
                                key={singleField.requestKey}
                                pattern="^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$"
                                value={
                                  isDataPresent
                                    ? this.state.data[singleField.requestKey]
                                    : ''
                                }
                                name={singleField.requestKey}
                                label={singleField.fieldlabel}
                                placeholder={singleField.fieldlabel}
                                type="text"
                                errorMessage={singleField.errorMessage}
                                validate={singleField.validate}
                                grid={{ lg: 8, md: 8, sm: 8 }}
                              />
                            );
                          } else if (singleField.type === 'Multiple Select') {
                            return (
                              <FormGroup
                                row
                                className="align-items-center"
                                key={singleField.requestKey}
                              >
                                <Label
                                  className={
                                    this.state.isValidSelect[
                                      singleField.requestKey
                                    ] === false
                                      ? 'text-danger col-xs-12 col-sm-4 col-md-4 col-lg-4 control-label mb-0'
                                      : 'col-xs-12 col-sm-4 col-md-4 col-lg-4 control-label mb-0'
                                  }
                                >
                                  {singleField.fieldlabel}
                                </Label>
                                <div className="col-xs-12 col-sm-6 col-md-8 col-lg-8 select2">
                                  <Select
                                    isMulti={true}
                                    styles={customSelect2StylesMulty(
                                      singleField.requestKey
                                    )}
                                    maxMenuHeight={200}
                                    getOptionLabel={(option) => {
                                      return option[singleField.optionlabel];
                                    }}
                                    getOptionValue={(option) => {
                                      return option[singleField.optionvalue];
                                    }}
                                    isClearable={false}
                                    onChange={(event) => {
                                      this.setState((prevState) => {
                                        let dependantValue = {};
                                        let dependantOption = {};
                                        if (singleField.dependantKey) {
                                          dependantValue = {
                                            [singleField.dependantKey]: '',
                                          };
                                          dependantOption = {
                                            [singleField.dependantKey +
                                            'Options']: [],
                                            [singleField.dependantKey +
                                            'Value']: [],
                                          };
                                        }
                                        return {
                                          [singleField.requestKey +
                                          'Value']: event,
                                          allSelectValues: {
                                            ...prevState.allSelectValues,
                                            [singleField.requestKey]: event
                                              ? event.map((singleEvent) => {
                                                  return singleEvent[
                                                    singleField.optionvalue
                                                  ];
                                                })
                                              : [],
                                            ...dependantValue,
                                          },
                                          ...dependantOption,
                                          isValidSelect: {
                                            ...prevState.isValidSelect,
                                            [singleField.requestKey]: true,
                                          },
                                        };
                                      });
                                    }}
                                    value={
                                      this.state[
                                        singleField.requestKey + 'Value'
                                      ] ?? []
                                    }
                                    options={
                                      this.state[
                                        singleField.requestKey + 'Options'
                                      ]?.length > 0 ||
                                      this.props.plantIdInSelectShift === 'true'
                                        ? this.state[
                                            singleField.requestKey + 'Options'
                                          ]
                                        : this.getData(
                                            singleField.path,
                                            singleField.requestKey + 'Options',
                                            singleField.hierarchykey
                                          )
                                    }
                                    className="select2"
                                  />
                                  {this.state.isValidSelect[
                                    singleField.requestKey
                                  ] === false ? (
                                    <div className="invalid-feedback">
                                      {
                                        singleField.validate.required
                                          .errorMessage
                                      }
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </FormGroup>
                            );
                          } else {
                            return null;
                          }
                        } else {
                          return null;
                        }
                      })}
                      <Row noGutters className="justify-content-end">
                        {!this.props.hideBackLink &&
                          (this.props.redirectToDiffLink ? (
                            <Link
                              to={`${this.props.redirectPath}/${this.props.match.params.id}`}
                            >
                              <button
                                type="button"
                                className="waves-effect btn btn-link mr-3"
                              >
                                Back
                              </button>
                            </Link>
                          ) : this.props.redirectToDiffEditLink ? (
                            <button
                              onClick={() =>
                                this.setState({ shouldRedirect: true })
                              }
                              type="button"
                              className="waves-effect btn btn-link mr-3"
                            >
                              Back
                            </button>
                          ) : (
                            <Link to={this.props.redirectPath}>
                              <button
                                type="button"
                                className="waves-effect btn btn-link mr-3"
                              >
                                Back
                              </button>
                            </Link>
                          ))}
                        <Button
                          color="primary"
                          className="bwaves-effect waves-light"
                        >
                          Submit
                        </Button>
                      </Row>
                    </AvForm>
                  )}
                </CardBody>
              </Col>
            </Card>
          </Col>
        </Row>
        {/* sap csv redirect */}
        {this.state.sap_csv_redirect && (
          <Redirect
            to={{
              pathname: `${this.props.redirectPath}/tabular-view/${this.state.allSelectValues.plantid}/${this.state.sap_csv_date}`,
              state: { date: this.state.sap_csv_date },
            }}
          />
        )}
        {/* feeder add from  condition  */}
        {this.props.redirectToDiffLink
          ? this.state.shouldRedirect && (
              <Redirect
                to={`${this.props.redirectPath}/${this.props.match.params.id}`}
              />
            )
          : null}

        {/* feeder edit form condition */}
        {this.state.reel_tracking_redirect && (
          <Redirect to={`${this.props.redirectPath}/${this.state.reel_no}`} />
        )}
        {this.state.b_mileage_redirect && (
          <Redirect
            to={`${this.props.redirectPath}/${this.state.plantidValue.id}/${this.state.machine_nameValue.id}`}
          />
        )}
        {this.props.redirectToDiffEditLink
          ? this.state.shouldRedirect && (
              <Redirect
                to={`${this.props.redirectPath}/${this.state.data.server_id._id}`}
              />
            )
          : null}
        {this.props.normalRedirect
          ? this.state.shouldRedirect && (
              <Redirect to={this.props.redirectPath} />
            )
          : null}
      </React.Fragment>
    );
  }
}

export default connect(null, { setBreadcrumbItems, setBreadcrumbButtonAction })(
  FormAdvanced
);
