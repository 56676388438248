import React, { Component } from "react";
import { Row, Col, Card, Media, Button, Modal, ModalBody } from "reactstrap";
import { getLoggedInUser } from "../../helpers/authUtils";
import user from "../../assets/images/avtar.png";
import moment from "moment";
let last_login = "";
let image = "";

export default class UserProfile extends Component {
  state = {
    cname: "",
    last_login: "",
    title: "",
    division: "",
    modal: true,
  };
  async componentDidMount() {
    let details = getLoggedInUser();
    this.setState({
      cname: details.common_name,
      office: details.physical_delivery_office_name,
      designation: details.title,
      division: details.division,
      department: details.department,
      location: details.location,
      company: details.company,
      mobile: details.mobile,
      thumbnail_photo: details.thumbnail_photo,
      email: details.mail,
      last_login: details.last_login,
    });

    // last_login = String(new Date(details.last_login / 1e4 - 1.16444736e13));
    // let date = last_login.split("GMT");
    // this.setState({
    //   last_login: date[0],
    // });

    image = "data:image/jpeg;base64," + details.thumbnail_photo;
    // console.log("image: ", image);
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    console.log("this.state", this.state);
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          style={{ maxWidth: "600px" }}
        >
          <ModalBody>
            <Row className="profile">
              <Col>
                <Card className="directory-card">
                  <div className="text-center">
                    <div className="directory-overlay">
                      <img
                        className="rounded-circle avatar-lg img-thumbnail"
                        src={this.state.thumbnail_photo ? image : user}
                        alt="Profile Pic"
                      />
                    </div>
                  </div>

                  <div className="directory-content text-center ">
                    <h5 className="font-size-16 bold-text mt-4 mb-0">
                      {this.state.cname}
                    </h5>
                    <p className="bold-text mt-1 mb-1">{this.state.email}</p>
                    <p className="text-capitalize text-muted mb-0">
                      {this.state.designation}
                    </p>

                    <p className="text-uppercase text-muted m-0 mb-3">
                      {this.state.office}
                    </p>
                    <Row noGutters className="person-Details">
                      <Col className="text-left">
                        <Row noGutters className="more-info">
                          <Col
                            xs="12"
                            sm="12"
                            md="6"
                            lg="6"
                            className="more-info-card"
                          >
                            <Row noGutters>
                              <Col>
                                <Row
                                  noGutters
                                  className="info align-items-center"
                                >
                                  <div className="icon">
                                    <span className="mr-3">
                                      <i className="dripicons-location"></i>
                                    </span>
                                  </div>
                                  <Media className="chat-user-box" body>
                                    <p className="text-muted mb-0">Location</p>
                                    <p className="user-title m-0">
                                      {this.state.location}
                                    </p>
                                  </Media>
                                </Row>
                                <Row
                                  noGutters
                                  className="info align-items-center"
                                >
                                  <div className="icon">
                                    <span className="mr-3">
                                      <i className="dripicons-phone"></i>
                                    </span>
                                  </div>
                                  <Media className="chat-user-box" body>
                                    <p className="text-muted mb-0">
                                      Contact No.
                                    </p>
                                    <p className="user-title m-0">
                                      +{this.state.mobile}
                                    </p>
                                  </Media>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs="12"
                            sm="12"
                            md="6"
                            lg="6"
                            className="more-info-card"
                          >
                            <Row noGutters>
                              <Col>
                                <Row
                                  noGutters
                                  className="info align-items-center"
                                >
                                  <div className="icon">
                                    <span className="mr-3">
                                      <i className="dripicons-suitcase"></i>
                                    </span>
                                  </div>
                                  <Media className="chat-user-box" body>
                                    <p className="text-muted mb-0">Company</p>
                                    <p className="user-title m-0">
                                      {this.state.company}
                                    </p>
                                  </Media>
                                </Row>
                                <Row
                                  noGutters
                                  className="info align-items-center"
                                >
                                  <div className="icon">
                                    <span className="mr-3">
                                      <i className="dripicons-power"></i>
                                    </span>
                                  </div>
                                  <Media className="chat-user-box" body>
                                    <p className="text-muted mb-0">
                                      Last Login
                                    </p>
                                    <p className="user-title m-0">
                                      {moment(this.state.last_login).format(
                                        "DD MMM YYYY HH:mm"
                                      )}
                                    </p>
                                  </Media>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row noGutters className="justify-content-end mb-3">
                      <Button color="secondary" onClick={this.props.toggle}>
                        Cancel
                      </Button>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
