import React, { Component } from "react";
import { connect } from "react-redux";
import PaginationComponent from "react-reactstrap-pagination";

class PaginationDataTable extends Component {
  state = {
    selectedPage: this.props.currentPage,
  };
  handleSelected = (selectedPage) => {
    this.props.handlePagination(selectedPage);
  };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <nav
          className="d-flex align-items-center justify-content-between mt-3"
          aria-label="..."
        >
          <p className="m-0">
            Showing {(this.props.currentPage - 1) * this.props.dataLimit + 1} To{" "}
            {this.props.currentPage * this.props.dataLimit} of{" "}
            {this.props.totalPageData} entries
          </p>
          <PaginationComponent
            totalItems={Number(this.props.totalPageData)}
            pageSize={Number(this.props.dataLimit)}
            onSelect={this.handleSelected}
            maxPaginationNumbers={9}
            defaultActivePage={this.props.currentPage}
          />
        </nav>
      </React.Fragment>
    );
  }
}

export default connect(null, {})(PaginationDataTable);
