export default {
  "Sub Modules": [
    {
      fieldlabel: "Select Module Name",
      validate: {
        required: {
          value: true,
        },
      },
      path: "gigs",
      requestKey: "module_id",
      optionlabel: "name",
      optionvalue: "_id",
      type: "Select",
    },
  ],
  Pages: [
    {
      fieldlabel: "Select Module",
      validate: {
        required: { value: true, errorMessage: "Select Module" },
      },
      path: "gigs",
      requestKey: "module_id",
      optionlabel: "name",
      optionvalue: "_id",
      dependantKey: "submodule_id",
      type: "Select",
      addform: true,
      editform: true,
    },
    {
      fieldlabel: "Select Sub Module",
      validate: {
        required: {
          value: true,
          errorMessage: "Select Sub Module",
        },
      },
      path: "submodule/getByModule/",
      requestKey: "submodule_id",
      optionlabel: "Name",
      optionvalue: "_id",
      type: "Select",
      hierarchykey: "module_id",
      addform: true,
      editform: true,
    },
  ],
  Products: [
    {
      fieldlabel: "Product Name",
      requestKey: "name",
      type: "Textbox",
      addform: true,
      editform: true,
    },
    {
      fieldlabel: "Sap Code",
      requestKey: "sapcode",
      type: "Textbox",
      addform: true,
      editform: true,
    },
  ],
  Operators: [
    {
      fieldlabel: "Select Plant",
      validate: {
        required: { value: true, errorMessage: "Select  Plant" },
      },
      path: "plant",
      requestKey: "plantid",
      optionlabel: "PlantName",
      optionvalue: "id",
      type: "Select",
      addform: true,
      editform: true,
    },
  ],
  "Holiday": [
    {
        fieldlabel: "Select Date",
        requestKey: "holiday_date",
        type: "Date",
        addform: true,
        editform: true,
    },
  ],
};
