import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    toggleSidebar,
    hideRightSidebar,
    changeTopbarTheme,
    changeLayout,
    changeSidebarTheme,
    changeLayoutWidth,
    changeSidebarType,
    changePreloader,
} from '../../../store/actions';
import axios from 'axios';
import { API_URL, getLoggedInUser } from './../../../helpers/authUtils'

// MetisMenu
import MetisMenu from 'metismenujs';

import masterList from './masterList.json';

class SidebarContent extends Component {

    state = {
        menuItems: []
    }

    hayStackUrls = [];
    options = [];
    masterList = masterList;

    async componentDidMount() {
        //document.body.setAttribute('data-sidebar', 'light');
        let user = await getLoggedInUser();
        if(user.id == 1){
            this.setState({menuItems: this.masterList}, () => this.initMenu());
        } else {
            await axios.get(`${API_URL}usergroup/findByCN/${user.user_group_common_name}`).then(response => {
                if(response.data.permission){
                    //generate array of page urls
                    response.data.permission.forEach((item, i) => this.hayStackUrl(item));
                    for(var i in this.masterList){
                        let item = this.masterList[i];
                        this.options = [];
                        this.crossCheck(item, i);
                    }

                    //filtering menuitems with zero options;
                    this.masterList = this.masterList.filter(function f(o) {
                      if (o.options) {
                        return (o.options = o.options.filter(f)).length > 0
                      } else {
                        return true;
                      }
                    })

                    this.setState({menuItems: this.masterList}, () => {
                    	this.initMenu();
                    })        
                }
            })
        }
    }

    crossCheck = async (needle, i) => {
        this.options.push(i);
        if(needle.hasOwnProperty("address")){
            let tempArr = this.hayStackUrls.filter(data => data.page_url === needle.address && data.checked === true);
            if(tempArr.length === 0){
                this.options.pop();
                //let delStr = "this.masterList["+this.options.join('][')+"].splice("+this.deleteIndex+", 1)"
                let delStr = "delete this.masterList["+this.options.join('][')+"]["+i+"]"
                try {
                    eval(delStr);
                } catch (e) {
                    console.log(e.message)
                }
            } else {
                this.options.pop();
            }
        } else {
            if(needle.hasOwnProperty('options')){
                this.outOfOption = 0;
                this.options.push("'options'")
                let options = JSON.parse(JSON.stringify(needle.options));
                options.forEach(async (subitem, n) => {
                    await this.crossCheck(subitem, n);
                })
                this.options.pop();
                this.options.pop();
            }
        }
    }

    hayStackUrl = (data) => {
        if(!data.hasOwnProperty("page_url") && data.children){
            data.children.forEach(async (child, n) => await this.hayStackUrl(child))
        } else {
            this.hayStackUrls.push(data);
        }
    }

    //update local state after changing layout
    /*componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                layoutType: this.props.layoutType,
                topbarTheme: this.props.topbarTheme,
                leftSideBarTheme: this.props.leftSideBarTheme,
                layoutWidth: this.props.layoutWidth,
                sidebarType: this.props.leftSideBarType,
                isPreloader: this.props.isPreloader,
            });
        }

        if (this.props.leftSideBarType !== prevProps.leftSideBarType) {
            this.initMenu();
        }
    }*/

    initMenu() {
        new MetisMenu('#side-menu');

        var matchingMenuItem = null;

        var ul = document.getElementById('side-menu');
        var items = ul.getElementsByTagName('a');
        let matchedItemPathlabel = -1;
        var splitPath = this.props.location.pathname.split('/');

        for (var i = 0; i < items.length; ++i) {
            let tempLabel = 0;
            let currentClickedPath = items[i].pathname.split('/');
            let [minLengthPath, maxLengthPath] =
                splitPath.length < currentClickedPath.length
                    ? [splitPath, currentClickedPath]
                    : [currentClickedPath, splitPath];
            minLengthPath.forEach((singlePath, i) => {
                if (singlePath === maxLengthPath[i]) {
                    tempLabel++;
                }
            });
            if (tempLabel > matchedItemPathlabel) {
                matchedItemPathlabel = tempLabel;
                matchingMenuItem = items[i];
            }
        }

        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = (item) => {
        if (item === document.getElementById('side-menu')) {
            return;
        }
        item.classList.add(item.tagName === 'UL' ? 'mm-show' : 'mm-active');

        if (item.tagName === 'LI') {
            item.childNodes[0].classList.add('mm-active');
        }

        if (item.parentElement) {
            this.activateParentDropdown(item.parentElement);
        }
    };

    //change layput type and dispatch action
    changeLayout() {
        this.props.changeLayout('horizontal');
    }

    //theme preloader
    changeThemePreloader = (value) => {
        this.props.changePreloader(!this.props.isPreloader);
    };

    //change left sidebar theme
    changeLeftSidebarTheme(value) {
        this.props.changeSidebarTheme(value);
    }

    //change layout width
    changeLayoutWidth(value) {
        if (this.state.layoutWidth === 'boxed')
            this.props.changeLayoutWidth('fluid', this.state.layoutType);
        else this.props.changeLayoutWidth('boxed', this.state.layoutType);
    }

    //change topbar theme and dispatch action
    changeTopbarTheme(value) {
        this.props.changeTopbarTheme(value);
    }

    //change sidebar type
    changeLeftSidebarType(value) {
        this.props.changeSidebarType(value);
    }

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {this.state.menuItems.length > 0 && this.state.menuItems.map((singleSubList, index) => (
                            <li key={singleSubList + index}>
                                <Link
                                    to={singleSubList.hasOwnProperty('address') ? singleSubList.address : '/#'}
                                    className={singleSubList.hasOwnProperty('options') ? 'has-arrow waves-effect' : 'waves-effect'}
                                >
                                    {singleSubList.hasOwnProperty('icon') && <i className={"mdi "+singleSubList.icon}></i>}
                                    <span>{singleSubList.name}</span>
                                </Link>
                                {singleSubList.hasOwnProperty('options') && 
                                    <ul className="sub-menu" aria-expanded="false" id="side-menu-inside">
                                        {singleSubList.options.map((item, index) => (
                                            <li key={item + index}>
                                                <Link
                                                    to={item.hasOwnProperty('address') ? item.address : '/#'}
                                                    className={item.hasOwnProperty('options') ? 'has-arrow waves-effect' : 'waves-effect'}
                                                >
                                                    <span>{item.name}</span>
                                                </Link>

                                                {item.hasOwnProperty('options') && 
                                                    <ul className="sub-menu" aria-expanded="false">
                                                        {item.options.map((subitem, idx) => {
                                                            if(subitem.hasOwnProperty('status') && subitem.status === 0){
                                                                return null;
                                                            } else {
                                                                return (<li key={subitem + idx}>
                                                                    <Link
                                                                        to={subitem.hasOwnProperty('address') ? subitem.address : '/#'}
                                                                        className={subitem.hasOwnProperty('options') ? 'has-arrow waves-effect' : 'waves-effect'}
                                                                    >
                                                                        <span>{subitem.name}</span>
                                                                    </Link>

                                                                    {subitem.hasOwnProperty('options') && 
                                                                        <ul className="sub-menu" aria-expanded="false">
                                                                            {subitem.options.map((subsubitem, x) => (
                                                                                <li key={subsubitem + x}>
                                                                                    <Link
                                                                                        to={subsubitem.hasOwnProperty('address') ? subsubitem.address : '/#'}
                                                                                        className={subsubitem.hasOwnProperty('options') ? 'has-arrow waves-effect' : 'waves-effect'}
                                                                                    >
                                                                                        <span>{subsubitem.name}</span>
                                                                                    </Link>
                                                                                </li>    
                                                                            ))}
                                                                        </ul>
                                                                    }
                                                                </li>);
                                                            }   
                                                        })}
                                                    </ul>
                                                }
                                            </li>    
                                        ))}
                                    </ul>
                                }
                            </li>
                            )
                        )}

                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    return {};
};

export default withRouter(
    connect(mapStatetoProps, {
        toggleSidebar,
        hideRightSidebar,
        changeLayout,
        changeTopbarTheme,
        changeSidebarTheme,
        changeLayoutWidth,
        changeSidebarType,
        changePreloader,
    })(SidebarContent)
);
