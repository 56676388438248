import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import $ from "jquery";

import { toggleRightSidebar } from "../../store/actions";

class Breadcrumb extends Component {
  //this func is executing on utility-daily.js
  slideFilterPanel() {
    $("#filterPanel").slideToggle("fast");
  }

  rightsidebarToggle = () => {
    document.body.classList.toggle("right-bar-enabled");
    this.props.toggleRightSidebar(!this.show_rightsidebar);
  };

  render() {
    const itemLength = this.props?.breadcrumbItems?.length;
    return (
      <React.Fragment>
        <Row noGutters>
          <Col lg={12}>
            <div className="page-title-box">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h4 className="text-capitalize">{this.props.title}</h4>
                </div>

                <div>
                  {this.props?.breadcrumbButtons?.length > 0 &&
                    this.props.breadcrumbButtons.map((singleButton, index) => {
                      return (
                        <Link to={singleButton.buttonLink} key={index}>
                          <Button
                            onClick={() =>
                              singleButton.buttonFunc
                                ? this[singleButton.buttonFunc]()
                                : {}
                            }
                            type="button"
                            color="primary"
                            className="waves-effect waves-light ml-2"
                            dangerouslySetInnerHTML={{
                              __html: singleButton.buttonName,
                            }}
                          ></Button>
                        </Link>
                      );
                    })}
                </div>
              </div>
              <ol className="breadcrumb m-0">
                {this.props?.breadcrumbItems?.length > 0 &&
                  this.props.breadcrumbItems.map((item, key) =>
                    key + 1 === itemLength ? (
                      <li key={key} className="breadcrumb-item active">
                        {item.title}
                      </li>
                    ) : (
                      <li key={key} className="breadcrumb-item">
                        <Link to={item.link}>{item.title}</Link>
                      </li>
                    )
                  )}
              </ol>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const BreadcrumbData = state.Breadcrumb;
  const { show_rightsidebar } = state.Layout;
  return {
    show_rightsidebar,
    title: BreadcrumbData.title,
    breadcrumbItems: BreadcrumbData.breadcrumbItems,
    breadcrumbButtons: BreadcrumbData.breadcrumbButtons,
  };
};

export default withRouter(
  connect(mapStatetoProps, { toggleRightSidebar })(Breadcrumb)
);
