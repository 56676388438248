import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { FormGroup, Label, Button, UncontrolledAlert, Col,  Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import { hideRightSidebar } from "../../../store/actions";
import $ from "jquery";
import Select from "react-select";

import { API_URL } from "../../../helpers/authUtils";
import TableFilterConfig from "./TableFilterConfig";

import "react-perfect-scrollbar/dist/css/styles.css";
import "../../../component/Layout/rightbar.scss";

const instance = axios.create();

class TableFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: [],
      filter: {},
      textfilter: "",
      sapcode: "",
    };
  }

  // on componrnt mount store page data in state
  // componentDidMount() {
  //     this.setState({pageData:this.props.data})
  // }

  getData(path, stateKey, hierarchykey) {
    if (!hierarchykey || (hierarchykey && this.state.filter[hierarchykey])) {
      instance
        .get(
          API_URL + path + (hierarchykey ? this.state.filter[hierarchykey] : "")
        )
        .then((response) => {
          this.setState({ [stateKey]: response.data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  hideSidebar = () => {
    document.body.classList.remove("right-bar-enabled");
    this.props.hideRightSidebar();
  };

  //searched text
  handleSelectedText = (e, selectedValue) => {
    $("#errDiv").hide("medium");
    this.setState({
      filter: {
        ...this.state.filter,
        [selectedValue.requestKey]: e.target.value,
      },
    });
  };

  // setDate
  handleSelectedDate = (e, selectedValue) => {
    $("#errDiv").hide("medium");
    this.setState({
      filter: {
        ...this.state.filter,
        [selectedValue.requestKey]: e.target.value,
      },
    });
  };

  //On Select save value
  handleSelectValue = (selectedValue, singleField, requestKey) => {
    $("#errDiv").hide("medium");
    if (this.props.pageName === "Operators") {
      this.setState({ filter: { [requestKey]: selectedValue.id } }, () => {
        if (singleField.hasOwnProperty("dependantKey")) {
          let index = TableFilterConfig[this.props.pageName].findIndex(
            (item) => item.requestKey === singleField.dependantKey
          );

          let dependentField = TableFilterConfig[this.props.pageName][index];
          this.getData(
            dependentField.path,
            dependentField.requestKey + "Options",
            dependentField.hierarchykey
          );
        }
      });
    } else {
      this.setState({ filter: { [requestKey]: selectedValue._id } }, () => {
        if (singleField.hasOwnProperty("dependantKey")) {
          let index = TableFilterConfig[this.props.pageName].findIndex(
            (item) => item.requestKey === singleField.dependantKey
          );

          let dependentField = TableFilterConfig[this.props.pageName][index];
          this.getData(
            dependentField.path,
            dependentField.requestKey + "Options",
            dependentField.hierarchykey
          );
        }
      });
    }
  };

  handleSubmitFilter = (reset = 0) => {
    if (Object.keys(this.state.filter).length > 0 || reset === 1) {
      this.props.handleFilter(this.state.filter);
      this.hideSidebar();
    } else {
      $("#errDiv").show("medium");
    }
  };

  render() {
    const customSelect2Styles = () => ({
      control: (styles, state) => ({
        ...styles,
        fontSize: "14px",
        color: "#5b626b",
        borderRadius: 0,
        boxShadow: state.isFocused ? "#ced4da" : 0,
        borderColor: state.isFocused ? "#ced4da" : "#ced4da",
        "&:hover": {
          borderColor: state.isFocused ? "#ced4da" : "#ced4da",
        },
      }),
      singleValue: (style, state) => ({
        color: "#5b626b",
      }),
      option: (styles, state) => ({
        ...styles,
        fontSize: "14px",
      }),
    });

    return (
      <React.Fragment>
        <div className="right-bar">
          <PerfectScrollbar className="h-100">
            <div className="rightbar-title d-flex justify-content-between px-3 py-3">
              <h5 className="m-0 d-flex align-items-center">
                <span className="mr-1">
                  <i className="mdi mdi-filter-outline"></i>
                </span>
                Filter
              </h5>
              <Link
                to="#"
                onClick={this.hideSidebar}
                className="right-bar-toggle float-right"
              >
                <i className="mdi mdi-close noti-icon"></i>
              </Link>
            </div>

            <hr className="my-0" />

            <div className="p-3 pt-4">
              <UncontrolledAlert
                style={{ display: "none" }}
                id="errDiv"
                color="danger"
              >
                <strong>Error!</strong> Please enter values to filter data.
              </UncontrolledAlert>

              <AvForm onValidSubmit={this.handleSubmitFilter}>
                {TableFilterConfig[this.props.pageName].map(
                  (singleField, index) => {
                    if (singleField.type === "Select") {
                      return (
                        <FormGroup key={index}>
                          <Label className="control-label">
                            {singleField.fieldlabel}
                          </Label>
                          <Select
                            styles={customSelect2Styles()}
                            value={this.state[singleField.requestKey + "value"]}
                            onChange={(selectedValue) =>
                              this.handleSelectValue(
                                selectedValue,
                                singleField,
                                singleField.requestKey
                              )
                            }
                            getOptionLabel={(option) => {
                              return option[singleField.optionlabel];
                            }}
                            getOptionValue={(option) => {
                              return option[singleField.optionvalue];
                            }}
                            options={
                              this.state[singleField.requestKey + "Options"]
                                ?.length > 0
                                ? this.state[singleField.requestKey + "Options"]
                                : !singleField.hasOwnProperty("hierarchykey")
                                ? this.getData(
                                    singleField.path,
                                    singleField.requestKey + "Options",
                                    singleField.hierarchykey
                                  )
                                : []
                            }
                            className="select2"
                          />
                        </FormGroup>
                      );
                    } else if (singleField.type === "Textbox") {
                      return (
                        <AvField
                          key={singleField.requestKey}
                          value={
                            this.state.filter[singleField.requestKey] || ""
                          }
                          name={singleField.requestKey}
                          label={singleField.fieldlabel}
                          onChange={(selectedValue) =>
                            this.handleSelectedText(
                              selectedValue,
                              singleField,
                              singleField.requestKey
                            )
                          }
                          placeholder={singleField.fieldlabel}
                          type="text"
                          errorMessage={singleField.errorMessage}
                          validate={singleField.validate}
                          grid={{ lg: 8 }}
                        />
                      );
                    } else if (singleField.type === "Date") {
                      return (
                        <FormGroup row key={singleField.requestKey}>
                          <Label
                            for="example-month-input"
                            className="col-sm-2 col-form-label"
                          >
                            Select Month & Year
                          </Label>
                          <Col sm="10">
                            <Input
                            onChange={(selectedValue) =>
                              this.handleSelectedDate(
                                selectedValue,
                                singleField,
                                singleField.requestKey
                              )
                            }
                            name={singleField.requestKey}
                              className="form-control"
                              type="month"
                              defaultValue="2011-08"
                              id="example-month-input"
                            />
                          </Col>
                        </FormGroup>
                       
                      );
                    } else {
                      return null;
                    }
                  }
                )}
                <div className="float-right">
                  <Button
                    color="primary"
                    className="bwaves-effect waves-light mr-2"
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    className="waves-effect btn"
                    onClick={() => {
                      this.setState({ filter: {} }, () => {
                        this.handleSubmitFilter(1);
                      });
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </AvForm>
            </div>
          </PerfectScrollbar>
        </div>
        <div className="rightbar-overlay"></div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const Layout = state.Layout;
  return {
    layoutType: Layout.layoutType,
    leftSideBarTheme: Layout.leftSideBarTheme,
    layoutWidth: Layout.layoutWidth,
    topbarTheme: Layout.topbarTheme,
    leftSideBarType: Layout.leftSideBarType,
    isPreloader: Layout.isPreloader,
  };
};

export default connect(mapStatetoProps, {
  hideRightSidebar,
})(TableFilter);
