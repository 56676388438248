import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Label,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import axios from "axios";
import {
  API_URL,
  getLoggedInUser,
  getPlantId,
  setPlantId,
  pageActions,
  LPS_URL,
} from "../../../helpers/authUtils";
import moment from "moment";
import {
  setBreadcrumbItems,
  setButtonAction,
  setBreadcrumbButtonAction,
} from "../../../store/actions";
import { store } from "react-notifications-component";
import { AvForm, AvField } from "availity-reactstrap-validation";
import sweetAlert from "sweetalert";
//Table Component
import TableConfig from "./TableConfig";
import DataTable from "./DataTable";
import MovableDataTable from "./MovableDataTable";
import TableFilter from "../TableFilter/TableFilter";
import PaginationDataTable from "./PaginationDataTable";

const instance = axios.create();

class CommonDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{title: 'Home', link: '/dashbaord'}, {title: this.props.pageName}],
      pageData: [],
      addbuttonId: "",
      filteredData: [],
      filteredName: "",
      filter: {},
      currentLoggedInUser: {},
      currentPlant: "",
      currentPage: 1,
      totalPageData: "",
      dataLimit: "",
      totalPageList: "",
      randomKey: Math.ceil(Math.random() * 100),
      years: [],
    };
  }

  async componentDidMount() {
    
    this.props.setBreadcrumbItems(
      this.props.pageName,
      this.state.breadcrumbItems
    );

    let btn = [];

    if (this.props.match) {
      btn.push([
        {
          buttonName: "Back",
          buttonLink: this.props.backButtonLink,
        },
        {
          buttonName: this.props.buttonName,
          buttonLink: this.props.buttonLink + "/" + this.props.match.params.id,
        },
      ]);
    }
    if (this.props.filterType) {
      if (this.props.buttonName.toLowerCase().includes("add")) {
        btn.push([
          {
            buttonName: this.props.buttonName,
            buttonLink: this.props.buttonLink,
          },
        ]);
      } else {
        btn.push([
          {
            buttonName: this.props.buttonName,
            buttonLink: this.props.buttonLink,
          },
        ]);
      }

      btn.push([
        {
          buttonName: "<i class='mdi mdi-filter-outline'></i>Filter",
          buttonLink: "#",
          buttonFunc: "rightsidebarToggle",
        },
      ]);
    }
    if (this.props.buttonName || this.props.buttonLink) {
      if (this.props.buttonName.toLowerCase().includes("add")) {
          btn.push([
            {
              buttonName: this.props.buttonName,
              buttonLink: this.props.buttonLink,
            },
          ]);
      } else {
        btn.push([
          {
            buttonName: this.props.buttonName,
            buttonLink: this.props.buttonLink,
            buttonFunc: this.props.buttonFunc ?? "",
          },
        ]);
      }
    }
    if (this.props.pagination) {
      this.setState({ dataUrl: this.props.dataUrl });
    }

    this.props.setBreadcrumbButtonAction(btn.shift());
    

    //if table is dependent like feeder is dependent on ems
    if (this.props.tableType === "Dependent Table") {
      instance
        .get(this.props.dataUrl + "/" + this.props.match.params.id)
        .then((response) => {
          this.setState({
            pageData: response.data,
            dataLimit: response.data.length,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.getData();
    }
  }

  getData = () => {
    if (this.props.pagination === true) {
      instance
        .get(this.props.dataUrl+"/?v="+new Date().getTime()+"&page="+this.state.currentPage)
        .then(async (response) => {
          await this.setState({
            pageData: response.data.docs,
            totalPageData: response.data.total,
            currentPage: response.data.page,
            dataLimit: response.data.limit,
            totalPageList: response.data.pages,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      instance
        .get(this.props.dataUrl+"/?v="+new Date().getTime())
        .then(async (response) => {
          await this.setState({
            pageData: response.data
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleFilter = (filter) => {
    this.setState({ filter: filter });
  };

  filterData = async (e) => {
    await setPlantId({ plant_id: e.id, PlantName: e.PlantName });
    this.setState({ currentPlant: e.id });
    let endpoint = this.props.dataUrl.replace(API_URL, "").split("/");

    if (this.props.pagination === true) {
      endpoint.pop();
      endpoint.push(e.id);
      this.setState(
        { dataUrl: `${API_URL}${endpoint.join("/")}`, currentPage: 1 },
        () => this.getDataUsingPagination()
      );
    } else {
      this.setState({
        pageData: [],
        randomKey: Math.ceil(Math.random() * 100),
      });
      let url = `${API_URL}${endpoint[0]}/findByPlant/${e.id}`;
      if (this.props.pageName === "CTP Machines") {
        url = `${API_URL}${endpoint[0]}/find-by-plant-id/${e.id}`;
      }
      if (
        [
          "EMS",
          "CTP Config",
          "Aurosys",
          "PECOM",
          "CTP Operators Shift",
        ].indexOf(this.props.pageName) !== -1
      ) {
        endpoint.pop();
        url = `${API_URL}${endpoint.join("/")}/${e.id}`;
      }
      axios.get(url).then((response) => {
        this.setState({ pageData: response.data });
      });
    }
  };

  handleDelete = (obj) => {
    this.props.deleteUrl
        ? instance
            .delete(this.props.deleteUrl + "/" + obj.id)
            .then((response) =>
              this.setState({
                pageData: this.state.pageData.filter(
                  (singleItem) => singleItem.id !== obj.id
                ),
              })
            )
        : instance.delete(this.props.dataUrl + "/" + obj.id).then((response) =>
            this.setState({
              pageData: this.state.pageData.filter(
                (singleItem) => singleItem.id !== obj.id
              ),
            })
          );
  }

  handlePagination = (currentPage) => {
    this.setState({ currentPage: currentPage }, () => {
      this.getData();
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    });
  };

  notify = (obj) => {
    return store.addNotification({
      title: obj.title,
      message: obj.message,
      type: obj.type,
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
      },
    });
  };

  filterIssueData = () => {
    let fromdate = document.getElementById("fromdate").value;
    let todate = document.getElementById("todate").value;

    if (!fromdate && !todate) {
      this.notify({
        type: "danger",
        message: "Please select dates to filter.",
      });
    } else if (!fromdate) {
      this.notify({
        type: "danger",
        message: "Please select from date.",
      });
    } else if (
      todate &&
      new Date(fromdate).getTime() > new Date(todate).getTime()
    ) {
      this.notify({
        type: "danger",
        message: "From date cannot be greater than to date.",
      });
    } else {
      let data = {
        plantid: this.state.plant_id ?? this.state.currentPlant,
        from_date: fromdate,
        to_date: todate,
      };
      axios.post(`${API_URL}issue`, data).then((response) => {
        this.setState({ pageData: response.data });
      });
    }
  };

  
  render() {
    const inputStyle = {
      height: 37,
      borderRadius: 3,
    };

    return (
      <React.Fragment>
        <Row>
          {this.props.pageName === "View / Modify Production Entry" && (
            <Col lg="12">
              <Card
                className="mb-2"
                style={{ display: "none" }}
                id="filterPanel"
              >
                <CardHeader>
                  <h5 style={{ marginBottom: "0px" }}>
                    <i className="mdi mdi-filter-outline"></i> Filter Data
                  </h5>
                  <hr className="mb-0" />
                </CardHeader>
                <CardBody className="pt-0">
                  <Row>
                    {this.state?.currentLoggedInUser?.is_admin === true && (
                      <Col lg="3" md="3" sm="6" xs="12">
                        <FormGroup>
                          <Label className="col-form-label">Plant</Label>
                          <Select
                            placeholder="Filter by plant..."
                            maxMenuHeight={200}
                            defaultValue={
                              this.state.allPlants &&
                              this.state.allPlants.filter(
                                (item) => item.id === this.state.currentPlant
                              )
                            }
                            getOptionLabel={(option) => {
                              let label = option.PlantName;
                              if (this.props.showAllPlants) {
                                label += " (";
                                switch (option.PlantType) {
                                  case 1:
                                    label += "In House";
                                    break;
                                  case 2:
                                    label += "MMCL";
                                    break;
                                  case 3:
                                    label += "OSP";
                                    break;
                                }
                                label += ")";
                              }
                              return label;
                            }}
                            getOptionValue={(option) => {
                              return option.id;
                            }}
                            onChange={(e) => this.setState({ plant_id: e.id })}
                            options={
                              this.state?.allPlants?.length > 0
                                ? this.state.allPlants
                                : []
                            }
                            className="select2"
                          />
                        </FormGroup>
                      </Col>
                    )}

                    <Col lg="3" md="3" sm="6" xs="12">
                      <FormGroup>
                        <Label className="col-form-label" for="from_date">
                          From Date
                        </Label>
                        <Input
                          style={inputStyle}
                          name="fromdate"
                          className="form-control"
                          type="date"
                          id="fromdate"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="3" md="3" sm="6" xs="12">
                      <FormGroup>
                        <Label className="col-form-label" for="todate">
                          To Date
                        </Label>
                        <Input
                          style={inputStyle}
                          name="to_date"
                          className="form-control"
                          type="date"
                          id="todate"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="3" md="3" sm="6" xs="12">
                      <Button
                        color="primary"
                        className="bwaves-effect waves-light ml-2"
                        style={{ marginTop: "28px" }}
                        onClick={() => this.filterIssueData()}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}

          {this.state?.currentLoggedInUser?.is_admin === true &&
            this.props.plantFilter && (
              <Col lg="12">
                <Card className="mb-2" id="filterPanel">
                  <CardBody>
                    <Row>
                      <div className="col-lg-3 pl-0 col-md-3 col-sm-6 col-xs-6 select2">
                        <Select
                          placeholder="Filter by plant..."
                          maxMenuHeight={200}
                          value={
                            this.state.allPlants &&
                            this.state.allPlants.filter(
                              (item) => item.id === this.state.currentPlant
                            )
                          }
                          getOptionLabel={(option) => {
                            let label = option.PlantName;
                            if (this.props.showAllPlants) {
                              label += " (";
                              switch (option.PlantType) {
                                case 1:
                                  label += "In House";
                                  break;
                                case 2:
                                  label += "MMCL";
                                  break;
                                case 3:
                                  label += "OSP";
                                  break;
                              }
                              label += ")";
                            }
                            return label;
                          }}
                          getOptionValue={(option) => {
                            return option.id;
                          }}
                          onChange={this.filterData}
                          options={
                            this.state?.allPlants?.length > 0
                              ? this.state.allPlants
                              : []
                          }
                          className="select2"
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}

          <Col lg="12">
            <Card>
              <CardBody>
                
                {this.props.tableCategory === "Movable" ? (
                  <MovableDataTable
                    columns={TableConfig[this.props.pageName]}
                    data={this.state.pageData}
                    editButtonLink={this.props.editButtonLink}
                    handleDelete={this.handleDelete}
                    gearButtonLink={this.props.gearButtonLink}
                    priorityPostLink={this.props.priorityPostLink}
                  />
                ) : (
                  <DataTable
                    key={this.state.randomKey ?? 1}
                    columns={TableConfig[this.props.pageName]}
                    pagename={this.props.pageName}
                    customCssClass={this.props.customCssClass}
                    data={this.state.pageData}
                    actions={this.props.actions ?? true}
                    md={this.props.md ?? true}
                    continueButton={this.props.continueButton}
                    deleteUrl={this.props.deleteUrl}
                    switch={this.props.switch}
                    editButtonLink={this.props.editButtonLink}
                    viewButtonLink={this.props.viewButtonLink}
                    handleDelete={this.handleDelete}
                    filterData={this.state.filter}
                    startIndex={
                      (this.state.currentPage - 1) * this.state.dataLimit + 1
                    }
                  />
                )}
                {this.props.pagination === true &&
                Object.keys(this.state.filter).length === 0 ? (
                  <PaginationDataTable
                    totalPageData={this.state.totalPageData}
                    currentPage={this.state.currentPage}
                    dataLimit={this.state.dataLimit}
                    totalPageList={this.state.totalPageList}
                    handlePagination={this.handlePagination}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.props.filterType && (
          <TableFilter
            pageName={this.props.pageName}
            handleFilter={this.handleFilter}
            formType="select"
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect(null, {
  setBreadcrumbItems,
  setButtonAction,
  setBreadcrumbButtonAction,
})(CommonDataTable);
