import React, { Component } from 'react';
import { Col, Row, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { getLoggedInUser } from '../../../helpers/authUtils';
import { connect } from 'react-redux';
import {
  AvForm,
  AvField,
  AvRadio,
  AvInput,
  AvRadioGroup,
  AvGroup,
  AvCheckbox,
  AvCheckboxGroup,
} from 'availity-reactstrap-validation';

//Import Multiple Select
import Select from 'react-select';
import axios from 'axios';
import { store } from 'react-notifications-component';

//Import Action to copy breadcrumb items from local state to redux state
import {
  setBreadcrumbItems,
  setBreadcrumbButtonAction,
} from '../../../store/actions';

import { API_URL } from '../../../helpers/authUtils';

import 'react-notifications-component/dist/theme.css';
import 'animate.css';

const instance = axios.create();

class Settings extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      breadcrumbItems: [],
      data: {}
    };
  }

  async componentDidMount() {
    this.props.setBreadcrumbItems(
      "Modify Settings",
      this.state.breadcrumbItems
    );

    this.props.setBreadcrumbButtonAction([]);

    
    instance.get(API_URL+'settings')
    .then((response) => {
      this.setState({ data: response.data });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  handleSubmit = (event, errors, values) => {
    if(errors.length > 0){
      return false;
    }

    instance.put(`${API_URL}settings/${this.state.data._id}`, values).then(response => {
      console.log(response);
      store.addNotification({
        title: 'Settings',
        message: 'Settings updated successfully',
        type: 'success',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      });
    })
    .catch(err => {
      store.addNotification({
        title: 'Error',
        message: err.message,
        type: 'error',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
      });
    })

  }
  

  render() {
    const customSelect2Styles = (id) => ({
      control: (styles, state) => ({
        ...styles,
        width: '100%',
        minHeight: 30,
        fontSize: '13px',
        color: '#5b626b',
        borderRadius: 0,
        boxShadow: state.isFocused ? '#ced4da' : 0,
        borderColor: state.isFocused
          ? '#ced4da'
          : this.state.isValidSelect[id] === undefined ||
            this.state.isValidSelect[id]
          ? '#ced4da'
          : '#ec536c',
        '&:hover': {
          borderColor: state.isFocused
            ? '#ced4da'
            : this.state.isValidSelect[id] === undefined ||
              this.state.isValidSelect[id]
            ? '#ced4da'
            : '#ec536c',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0 6px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '0px',
        flexWrap: 'initial',
      }),
      singleValue: (style, state) => ({
        color: '#000000',
      }),
      option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        svg: {
          color: state.isFocused
            ? '#ced4da'
            : this.state.isValidSelect[id] === undefined ||
              this.state.isValidSelect[id]
            ? '#ced4da'
            : '#ec536c',
        },
      }),
      indicatorContainer: (provided) => ({
        ...provided,
        svg: {
          padding: '0 8px',
        },
      }),
    });

    const customSelect2StylesMulty = (id) => ({
      control: (styles, state) => ({
        ...styles,
        width: '100%',
        minHeight: 30,
        fontSize: '13px',
        color: '#5b626b',
        borderRadius: 0,
        boxShadow: state.isFocused ? '#ced4da' : 0,
        borderColor: state.isFocused
          ? '#ced4da'
          : this.state.isValidSelect[id] === undefined ||
            this.state.isValidSelect[id]
          ? '#ced4da'
          : '#ec536c',
        '&:hover': {
          borderColor: state.isFocused
            ? '#ced4da'
            : this.state.isValidSelect[id] === undefined ||
              this.state.isValidSelect[id]
            ? '#ced4da'
            : '#ec536c',
        },
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding: '0 6px',
        lineHeight: '1px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '0px',
      }),
      singleValue: (style) => ({
        color: '#000000',
      }),
      multiValue: (provided, state) => ({
        ...provided,
        height: '20px',
        lineHeight: '1px',
        alignItems: 'center',
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        overflow: 'show',
      }),
      option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px',
        svg: {
          color: state.isFocused
            ? '#ced4da'
            : this.state.isValidSelect[id] === undefined ||
              this.state.isValidSelect[id]
            ? '#ced4da'
            : '#ec536c',
        },
      }),
      indicatorContainer: (provided) => ({
        ...provided,
        svg: {
          padding: '0 8px',
        },
      }),
    });

    let data = this.state.data;

    return (
      <React.Fragment>
        <Row noGutters>
          <Col lg="6">
            <Card className="row no-gutters">
              <Col className="d-flex">
                <CardBody>
                  {Object.keys(this.state.data).length > 0 && <AvForm onSubmit={this.handleSubmit}>
                    <AvField
                      key="feeds_duration"
                      name="feeds_duration"
                      label="Feeds Duration (Seconds)"
                      placeholder="Feeds Duration (Seconds)"
                      type="text"
                      grid={{ lg: 6, md: 6, sm: 6 }}
                      value={data.feeds_duration}
                      required
                    />
                    <Row noGutters className="justify-content-end">
                      <Button
                        color="primary"
                        className="bwaves-effect waves-light"
                      >
                        Submit
                      </Button>
                    </Row>
                  </AvForm>}
                </CardBody>
              </Col>
            </Card>
          </Col>
        </Row>
        
      </React.Fragment>
    );
  }
}

export default connect(null, { setBreadcrumbItems, setBreadcrumbButtonAction })(
  Settings
);
