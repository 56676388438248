import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import { store } from "react-notifications-component";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkLogin, clearErrorLogin, clearError } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import logimage from "../../assets/images/log.png";
import {
  API_URL,
  setLoggedInUser,
  getLoggedInUser,
} from "../../helpers/authUtils";
import axios from "axios";
import "./Login.css";

const instance = axios.create();

class Pageslogin extends Component {
  state = {
    username: "",
    password: "",
  };

  async componentDidMount() {
    this.props.clearErrorLogin();
    this.props.clearError();

    let user = await getLoggedInUser();
    if (user) {
      this.props.history.push("/dashboard");
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (event, value) => {
    let valid = true;

    if (!this.state.username) {
      valid = false;
      store.addNotification({
        message: "Please enter your username",
        type: "danger",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
        },
      });
    } else if (!this.state.password) {
      valid = false;
      store.addNotification({
        message: "Please enter your password",
        type: "danger",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
        },
      });
    }
    
    if (valid) {
      let obj = {
        username: this.state.username.toLowerCase(),
        password: this.state.password,
      };
      if (
        obj.username === "superadmin" && obj.password === "123pl123pl"
      ) {
        setLoggedInUser({
          id: "1",
          common_name: "Admin",
          given_name: "Admin",
          company: "Taedil Inc.",
          country: "India",
          mobile: 918976481012,
          mail: "support@taedil.ae",
          location: "Mumbai",
          state: "Maharashtra",
          display_name: "Admin",
          plant_id: "ODBCAIR28",
          is_admin: true,
          plant_details: {
            plant_id: "ODBCAIR28",
            PlantName: "Kandivali",
          },
          user_group_common_name: "prod_admin",
          user_group_name: "All India Admin",
        });
        this.props.history.push("/dashboard");
        return false;
      } else {
        store.addNotification({
          message: "Invalid Credentials! Please try again!",
          type: "danger",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
          },
        });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="loginParent">
          <div className="account-pages my-5 pt-sm-5">
            <Container
              style={{ backgroundColor: "#fff", maxWidth: '500px' }}
              className="shadow-lg p-3 bg-white position-absolute-lg"
            >
              <Row className="justify-content-center align-items-center h-100">
                {/*<Col md="8" lg="6" xl="7" className="p-0">
                  <img
                    src={logimage}
                    alt="logo"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Col>*/}
                <Col className="p-0">
                  <Card className="overflow-hidden shadow-none">
                    <CardBody className="pt-0">
                      <h3 className="text-center mt-4">
                        <Link to="/login" className="logo logo-admin">
                          <img src="/logo.png" height="100" alt="logo" />
                        </Link>
                      </h3>
                      <div className="p-3">
                        <h4 className="d-none text-muted font-size-18 mb-1 text-center">
                          Welcome Back !
                        </h4>
                        {/* <p className="text-muted text-center">
                                                  Sign in to continue to Lexa.
                                              </p> */}
                        {this.props.user && (
                          <Alert color="success">
                            Your Login is successful.
                          </Alert>
                        )}

                        {this.props.loginError && (
                          <Alert color="danger">{this.props.loginError}</Alert>
                        )}
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleSubmit}
                        >
                            <Label className="control-label" htmlFor="username">
                              Username
                            </Label>
                            <Input
                              type="text"
                              placeholder="Username"
                              className="form-control"
                              value={this.state.username}
                              name="username"
                              onChange={this.onChange}
                              style={{height: '40px'}}
                            />

                          <label className="mt-3" htmlFor="userpassword">Password</label>
                          <Input
                            name="password"
                            type="password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={this.onChange}
                            style={{height: '40px'}}
                          />

                          <div className="form-group row mt-4">
                            <Col xs="6"></Col>
                            <Col xs="6" className="text-right">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </Button>
                            </Col>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-2 text-center">
                    {/* <p>
                                          Don't have an account ?{' '}
                                          <Link
                                              to="/register"
                                              className="text-primary"
                                          >
                                              {' '}
                                              Signup Now{' '}
                                          </Link>
                                      </p> */}
                    <p>
                      © {new Date().getFullYear()}{" "}
                      
                        {" "}
                        -{" "}
                        <a href="https://www.taedil.com">
                        TAEDIL.
                        </a>{" "}
                        All rights reserved.
                      
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, loginError, loading } = state.Login;
  return { user, loginError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, clearErrorLogin, clearError })(
    Pageslogin
  )
);
