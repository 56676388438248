import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { API_URL, getPlantId, getLoggedInUser } from '../helpers/authUtils';
//Dashboard
import Dashboard from '../pages/Dashboard/dashboard';
import Icons from '../pages/Icons/icons-material';
import CommonDataTable from '../pages/Master/Tables/CommonDataTable';
import CommonDataForm from '../pages/Master/Forms/CommonDataForm';
import Settings from '../pages/Master/Forms/Settings';
import Pageslogin from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Pagesregister from '../pages/Authentication/Register';
import ForgetPassword from '../pages/Authentication/ForgetPassword';
import LockScreen from '../pages/Authentication/pages-lock-screen';
import AppRoute from './route';
import moment from 'moment';
import axios from 'axios';

let loggedInUser = {};

const AuthProtectedRoutes = (props) => {
  useEffect(() => {
    getUserData();
  }, []);
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [menus, setMenus] = useState([]);

  const getUserData = async () => {
    loggedInUser = await getPlantId();
    // loggedInUser = await getLoggedInUser();
    setMenus([
      // DashBoard
      { path: '/dashboard', component: Dashboard },
      {
        path: '/settings/config/categories',
        exact: true,
        component: () => (
          <CommonDataTable
            pageName="Categories"
            dataUrl={API_URL + 'categories'}
            deleteUrl={API_URL + 'categories'}
            customCssClass="table-bordered"
            buttonName="Add Category"
            buttonLink="/settings/config/categories/add"
            editButtonLink="/settings/config/categories/edit"
          />
        ),
      },
      {
        path: '/settings/config/categories/add',
        exact: true,
        component: () => (
          <CommonDataForm
            pageName="Category"
            entryPath="categories"
            normalRedirect="true"
            redirectPath="/settings/config/categories"
            formType="add"
            successMessage="Category added successfully"
          />
        ),
      },
      {
        path: '/settings/config/categories/edit/:id',
        exact: true,
        component: (props) => (
          <CommonDataForm
            {...props}
            pageName="Category"
            entryPath="categories/"
            normalRedirect="true"
            redirectPath="/settings/config/categories"
            formType="edit"
            successMessage="Category updated successfully"
          />
        ),
      },
      {
        path: '/settings/config/countries',
        exact: true,
        component: () => (
          <CommonDataTable
            pageName="Countries"
            dataUrl={API_URL + 'countries'}
            customCssClass="table-bordered"
            actions={false}
            switch
          />
        ),
      },
      {
        path: '/settings/general',
        exact: true,
        component: (props) => (
          <Settings />
        ),
      },
      {
        path: '/users',
        exact: true,
        component: () => (
          <CommonDataTable
            pageName="Users"
            dataUrl={API_URL + 'users'}
            customCssClass="table-bordered"
            //pagination
            deleteUrl={API_URL + 'users'}
            //editButtonLink="/users/edit-user"
            //filterTable="true"
            //filterType={true}
          />
        ),
      },
      {
        path: '/brands',
        exact: true,
        component: () => (
          <CommonDataTable
            pageName="Brands"
            //pagination
            customCssClass="table-bordered"
            dataUrl={API_URL + 'brands'}
            deleteUrl={API_URL + 'brands'}
            switch
            buttonName="Add Brand"
            buttonLink="/brands/add"
            editButtonLink="/brands/edit"
          />
        ),
      },
      {
        path: '/brands/add',
        exact: true,
        component: () => (
          <CommonDataForm
            pageName="Brands"
            entryPath="brands"
            normalRedirect="true"
            redirectPath="/brands"
            formType="add"
            successMessage="Brand added successfully"
          />
        ),
      },
      {
        path: '/brands/edit/:id',
        exact: true,
        component: (props) => (
          <CommonDataForm
            {...props}
            pageName="Brands"
            entryPath="brands/"
            normalRedirect="true"
            redirectPath="/brands"
            formType="edit"
            successMessage="Brand updated successfully"
          />
        ),
      },
      //wildcard page
      { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
    ]);
  };

  return menus.map((route, idx) => (
    <AppRoute
      path={route.path}
      component={route.component}
      layout={props.Layout}
      key={idx}
      isAuthProtected={true}
      exact
    />
  ));
};

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Pageslogin },
  { path: '/register', component: Pagesregister },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/pages-lock-screen', component: LockScreen },
  { path: '/icons', component: Icons },
];

export { AuthProtectedRoutes, publicRoutes };
