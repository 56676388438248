export default {
  Users: [
    {
      label: 'First Name',
      key: 'firstname',
      type: 'String',
    },
    {
      label: 'Last Name',
      key: 'lastname',
      type: 'String',
    },
    {
      label: 'Country',
      key: 'country',
      type: 'String',
    },
    {
      label: 'Mobile Numbers',
      key: 'mobile',
      type: 'String',
    },
    {
      label: 'Joined On',
      key: 'created_on',
      type: 'Date',
      format: "Do MMM Y"
    }
  ],
  'Categories': [
    {
      label: 'Category',
      key: 'name',
      type: 'String',
    },
    {
      label: 'Image',
      key: 'image',
      type: 'Image',
    },
  ],
  'Countries': [
    {
      label: 'Name',
      key: 'name',
      type: 'String',
    },
    {
      label: 'Phone Code',
      key: 'phonecode',
      type: 'String',
    },
    {
      label: 'Flag',
      key: 'flag',
      type: 'Image',
    },
  ],
  'Brands': [
    {
      label: 'Logo',
      key: 'logo',
      type: 'Image',
      alt: 'Logo'
    },
    {
      label: 'Image',
      key: 'image',
      type: 'Image',
      alt: 'Image'
    },
    {
      label: 'Name',
      key: 'name',
      type: 'String',
    },
    {
      label: 'Tagline',
      key: 'tagline',
      type: 'String',
    },
    {
      label: 'Website',
      key: 'website',
      type: 'Url',
    },
    {
      label: 'Category',
      key: 'category',
      type: 'String',
    },
    {
      label: 'Country',
      key: 'country',
      type: 'String',
    },
    {
      label: 'Onboarding',
      key: 'created_on',
      type: 'Date',
      format: "Do MMM Y"
    }
  ]
};
